import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { PageFooter } from "../../components/PageFooter";
import $ from "jquery";
import { bindIFrameEvent } from "../../utils/helpers";
import { proxy } from "../../client/proxy";

@observer
class ExternalPage extends React.Component {
  @observable hostedHtml = "";
  hostedHostname = "www.mycarebase.com";
  migratedHostname = "www2.mycarebase.com";

  constructor(props) {
    super(props);
  }

  setHostedPath = hostedPath => {
    this.hostedPath = hostedPath;
    return this.getExternalContent();
  };

  onPageLoad = callback => {
    if (typeof callback === "function") this.onPageLoadCallback = callback;
  };

  getExternalContent = async () => {
    this.hostedHtml = await this.getHtml(this.hostedPath).catch(console.warn);
    if (this.onPageLoadCallback) this.onPageLoadCallback();
  };

  getHtml = async pathname => proxy.getProxiedPageHtml(pathname).then(this.processHtml);

  processHtml = (html) => {
    if (!html) return;
    const $subDoc = $("<div></div>").append($.parseHTML(html));

    $subDoc.find("html").css("overflow-y", "hidden");
    $subDoc.find("base").remove();
    $subDoc.find("head").remove();
    $subDoc.find("meta").remove();
    $subDoc.find("noscript").remove();
    $subDoc.find("script").remove();
    $subDoc.find(".tm-header").remove();
    $subDoc.find(".tm-header-mobile").remove();
    $subDoc.find(".helpdeskdiv").remove();
    $subDoc.find(".footerkeywords").remove();
    $subDoc.find(".uk-section").last().remove(); // remove dev branding
    $subDoc.find(".uk-section").last().remove(); // remove original footer

    $subDoc.find(".uk-section:nth-child(3)")
    .find(".uk-container").css("justify-content", "center"); // Center top register section

    $subDoc.find(".uk-section:last-child")
    .find(".uk-container").css("justify-content", "center"); // Center bottom register section

    $subDoc.find(".uk-section:nth-last-child(2)")
    .find(".uk-container").css("justify-content", "center"); // Center logo section

    $subDoc.find(".uk-button-default").css("width", "200px");

    $subDoc.find(".uk-container").children("div").addClass("uk-grid");
    $subDoc.find(".uk-container.uk-margin").removeClass("uk-grid");
    $subDoc.find(".uk-container.uk-margin-small").removeClass("uk-grid");

    // <link />
    const $links = Array.from($subDoc.find("link"));
    for (const link of $links) {
      const href = $(link).attr("href");
      const regExp = new RegExp("https://(.*)", "g");
      if (href && !href.match(regExp)) {
        $(link).attr("href", `${proxy.proxyUrl}media/${this.migratedHostname}${href}`)
      }
    }

    // <img />
    const $imgs = Array.from($subDoc.find("img"));
    for (const img of $imgs) {
      const imgSrc = $(img).attr("data-src") || ($(img).attr("src") && $(img).attr("src").replace(/https:\/\/(.*)\//g, ""));
      $(img).attr("src", `${proxy.proxyUrl}media/${this.migratedHostname}${imgSrc}`)
    }

    // <a />
    const $as = Array.from($subDoc.find("a"));
    for (const a of $as) {
      const href = $(a).attr("href");
      const regExp = new RegExp(`https://${this.hostedHostname}/`, "g");
      if (href && href.match(regExp)) {
        $(a).attr("href", href.replace(regExp, "#"));
        // $(a).attr("target", "_blank");
      }
    }

    return $subDoc.html();
  };

  resizeFrame = () => {
    if (!this.iframe) return;
    this.bindBubbleEvents();
    const $frameElement = $(this.iframe.contentWindow.document.documentElement);
    $frameElement.css("overflowY", "hidden");
    const frameContentHeight = $frameElement.height();
    const frameStyleMinHeight = `${frameContentHeight}px`;
    if (this.iframe.style.minHeight !== frameStyleMinHeight) {
      this.iframe.style.minHeight = frameStyleMinHeight;
    }
  };

  bindBubbleEvents = () => {
    bindIFrameEvent(this.iframe, "click");
    bindIFrameEvent(this.iframe, "mousemove");
    bindIFrameEvent(this.iframe, "touchmove");
    bindIFrameEvent(this.iframe, "keypress");
    bindIFrameEvent(this.iframe, "resize");
    bindIFrameEvent(this.iframe, "scroll");
  };

  setFrameTarget = frame => {
    this.iframe = frame;
    window.addEventListener("frameResize", this.resizeFrame);
    client.rootContainerElm.addEventListener("scroll", this.resizeFrame);
  };

  render() {
    return <div className="page contact">
      <iframe
        ref={this.setFrameTarget}
        style={{ border: "none", flex: 1 }}
        srcDoc={this.hostedHtml}
        onLoad={this.resizeFrame}
      />
      <PageFooter />
    </div>;
  }
}

export { ExternalPage };