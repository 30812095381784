import * as React from "react";
import { observer } from "mobx-react";
import { SectionButton } from "../SectionButton";
import { getQueryParameters, preventDefaultStopProp, validateEmail } from "../../utils/helpers";
import $ from "jquery";
import "./styles.css";
import { observable } from "mobx";
import { SectionSubHeading } from "../SectionSubHeading";

@observer
class Covid19Form extends React.Component {
  @observable submitDisabled = true;

  validateForm = event => {
    const formData = $('#covid19Form').serialize();
    const data = getQueryParameters(formData);
    this.submitDisabled = !data["name"] || !data["email"] || !validateEmail(decodeURIComponent(data["email"]));
  };
  
  render() {
    const { props } = this;
    const onFormSubmit = event => {
      preventDefaultStopProp(event);
      const formData = $('#covid19Form').serializeArray();
      return props.onFormSubmit(formData);
    };

    return (
      props.showFinish ? (
        <SectionSubHeading className="covid19Form campaignForm">
          Thank you. We will be in touch with you soon.
        </SectionSubHeading>
      ) : (
        <form onChange={this.validateForm} id="covid19Form" className="covid19Form campaignForm">
          <div className="covid19FormFields">
            <div className="covid19FormField campaignFormField">
              <label>Name *</label>
              <input className="textField" type="text" name="name" />
            </div>
            <div className="covid19FormField campaignFormField">
              <label>Email *</label>
              <input className="textField" type="email" name="email" />
            </div>
            <div className="covid19FormField campaignFormField">
              <label>Phone (optional)</label>
              <input className="textField" type="tel" name="phone" />
            </div>
            <div className="covid19FormField campaignFormField">
              <label>Please describe your situation (optional)</label>
              <input className="textField" type="text" name="description" />
            </div>
          </div>
          <div className="covid19FormButtons campaignFormButtons">
            <SectionButton
              disabled={props.buttonsDisabled || this.submitDisabled}
              onClick={onFormSubmit}
              title="Submit"
              className="campaignPopupFormButton"
            />
          </div>
        </form>
      )
    );
  }
}

export { Covid19Form };