import { Observer, observer } from "mobx-react";
import * as $ from "jquery";
import * as React from "react";
import "./styles.css";
import { isEmpty } from "../../utils/helpers";
import ChatWidgetMessageItem from "../ChatWidgetMessageItem";

@observer
class ChatWidgetMessages extends React.Component {
  constructor (props) {
    super(props);
    setTimeout(this._autoScrollMessages);
  }

  componentDidUpdate() {
    this._autoScrollMessages();
  }

  _autoScrollMessages = () => {
    const $container = $(".chatWidgetMessages");
    $container.scrollTop($container[0].scrollHeight);
  };

  render() {
    const { messages } = this.props;
    return <div className="chatWidgetMessages">
      {!isEmpty(messages) && messages.map(message => (
        <Observer key={message.id}>{() => (
          <ChatWidgetMessageItem
            sender={message.sender}
            isSelf={message.isSelf}
            text={message.text}
            date={message.date}
            loading={message.loading}
          />
        )}</Observer>
      ))}
    </div>;
  }
}

export default ChatWidgetMessages;