export const randomItems = [
  {
    "id": "A",
    "component": "headline",
    "innerHTML": "Wish you could choose and hire the perfect caregiver?",
    "weight": 1
  },
  {
    "id": "B",
    "component": "headline",
    "innerHTML": "See why myCareBase is the future of caregiving!",
    "weight": 1
  },
  {
    "id": "A",
    "component": "chatPopup",
    "innerHTML": "Want some quick answers? Chat with a Care Advisor!",
    "weight": 1
  },
  {
    "id": "B",
    "component": "chatPopup",
    "innerHTML": "Want some free advice? Chat with a Care Advisor!",
    "weight": 1
  },
  {
    "id": "A",
    "component": "searchBoxTitle",
    "innerHTML": "Find affordable, pre-screened caregivers who meet your specific needs in your area!",
    "weight": 1
  }
];

export const toAbTestData = items => {
  if (!items) return;
  const result = {};
  for (const item of items) {
    result[item.component] = {
      displayed: item.id,
    };
    const allItems = randomItems.filter(i => i.component === item.component);
    for (const i of allItems) {
      result[item.component][i.id] = i.innerHTML;
    }
  }
  return result;
};