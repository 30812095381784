import { observer } from "mobx-react";
import React from "react";
import { SectionContent } from "../SectionContent";
import { Section } from "../Section";
import "./styles.css";
import { SectionSubHeading } from "../SectionSubHeading";
import { SectionButton } from "../SectionButton";
import { Fade, Slide } from "react-reveal";
import { getQueryParameters, preventDefaultStopProp, validateEmail } from "../../utils/helpers";
import $ from "jquery";
import { observable } from "mobx";
import { responsive } from "../../config/styles/responsive";
import { IoIosCloseCircleOutline } from "react-icons/io";

@observer
class CampaignPopup extends React.Component {
  @observable submitDisabled = true;

  validateForm = event => {
    const formData = $('#campaignPopupForm').serialize();
    const data = getQueryParameters(formData);
    this.submitDisabled = !data["name"] || !data["email"] || !validateEmail(decodeURIComponent(data["email"]));
  };

  withTransition = observer(props => (
    responsive.deviceDimension.isViewMax
      ? (
        <Fade right duration={props.duration}>
          {props.children}
        </Fade>
      ) : (
        <Slide bottom duration={props.duration}>
          {props.children}
        </Slide>
      )
  ));

  renderCleanupCampaignText = () => <>
    <SectionSubHeading>
      Not ready for a caregiver yet but want help with cleaning and house chores? We can take care of that too!
    </SectionSubHeading>
    <SectionSubHeading>
      Let us know if you're interested and we'll get back to you right away!
    </SectionSubHeading>
  </>;

  renderCovid19CampaignText = () => <>
    <SectionSubHeading>
      Are you worried about staying safe during this healthcare crisis while still having your care needs met?
    </SectionSubHeading>
    <SectionSubHeading>
      Are you interested in our special ad-hoc services to help with your home support or home healthcare needs during the COVID-19 crisis?
    </SectionSubHeading>
  </>;

  render() {
    const { props } = this;
    const onCampaignPopupYes = event => {
      preventDefaultStopProp(event);
      return props.onCampaignPopupYes();
    };
    const onCampaignPopupNo = event => {
      preventDefaultStopProp(event);
      return props.onCampaignPopupNo();
    };
    const onFormSubmit = event => {
      preventDefaultStopProp(event);
      const formData = $('#campaignPopupForm').serializeArray();
      return props.onFormSubmit(formData);
    };
    const onCampaignPopupClose = event => {
      preventDefaultStopProp(event);
      return props.onCampaignPopupClose();
    };

    const WithTransition = this.withTransition;
    return props.showPopup && <div className={`campaignPopupContainer ${props.withChat ? "withChat" : ""}`}>
      <WithTransition duration={750}>
        <div className={`campaignPopupWrapper ${props.showForm ? "withForm" : ""}`}>
          <div className="campaignPopup">
            {props.showForm && (
              <IoIosCloseCircleOutline
                className="campaignPopupCloseCross"
                color="#fff"
                size={30}
                onClick={onCampaignPopupClose}
              />
            )}
            <Section>
              <SectionContent className="campaignPopupContent">
                {props.showForm ? (
                  <form onChange={this.validateForm} id="campaignPopupForm" className="campaignForm">
                    <span className="campaignPopupFormHeading" />
                    <div className="campaignPopupFormFields">
                      <div className="campaignPopupFormField campaignFormField">
                        <label>Name *</label>
                        <input className="textField" type="text" name="name" />
                      </div>
                      <div className="campaignPopupFormField campaignFormField">
                        <label>Email *</label>
                        <input className="textField" type="email" name="email" />
                      </div>
                      <div className="campaignPopupFormField campaignFormField">
                        <label>Phone (optional)</label>
                        <input className="textField" type="tel" name="phone" />
                      </div>
                      <div className="campaignPopupFormField campaignFormField">
                        <label>Frequency</label>
                        <input className="textField" type="text" name="frequency" />
                      </div>
                      <div className="campaignPopupFormField campaignFormField">
                        <label>Preferred day(s) of the week</label>
                        <label className="checkboxLabel">
                          <input className="checkbox" type="checkbox" name="preferred_days.monday" />Mon.
                        </label>
                        <label className="checkboxLabel">
                          <input className="checkbox" type="checkbox" name="preferred_days.tuesday" />Tue.
                        </label>
                        <label className="checkboxLabel">
                          <input className="checkbox" type="checkbox" name="preferred_days.wednesday" />Wed.
                        </label>
                        <label className="checkboxLabel">
                          <input className="checkbox" type="checkbox" name="preferred_days.thursday" />Thu.
                        </label>
                        <label className="checkboxLabel">
                          <input className="checkbox" type="checkbox" name="preferred_days.friday" />Fri.
                        </label>
                        <label className="checkboxLabel">
                          <input className="checkbox" type="checkbox" name="preferred_days.saturday" />Sat.
                        </label>
                        <label className="checkboxLabel">
                          <input className="checkbox" type="checkbox" name="preferred_days.sunday" />Sun.
                        </label>
                      </div>
                      <div className="campaignPopupFormField campaignFormField">
                        <label>Preferred time(s) of the day</label>
                        <label className="checkboxLabel">
                          <input className="checkbox" type="checkbox" name="preferred_times.morning" />Morning
                        </label>
                        <label className="checkboxLabel">
                          <input className="checkbox" type="checkbox" name="preferred_times.afternoon" />Afternoon
                        </label>
                        <label className="checkboxLabel">
                          <input className="checkbox" type="checkbox" name="preferred_times.evening" />Evening
                        </label>
                        <label className="checkboxLabel">
                          <input className="checkbox" type="checkbox" name="preferred_times.anytime" />Anytime
                        </label>
                      </div>
                    </div>
                    <div className="campaignPopupFormButtons campaignFormButtons">
                      <SectionButton
                        disabled={props.buttonsDisabled || this.submitDisabled}
                        onClick={onFormSubmit}
                        title="Submit"
                        className="campaignPopupFormButton"
                      />
                      <SectionButton
                        disabled={props.buttonsDisabled}
                        onClick={onCampaignPopupClose}
                        title="Cancel"
                        className="campaignPopupFormButton"
                      />
                    </div>
                  </form>
                ) : props.showFinish ? (
                  <>
                    <SectionSubHeading>
                      Thank you. We will be in touch with you soon.
                    </SectionSubHeading>
                    <div className="campaignPopupButtons">
                      <SectionButton onClick={onCampaignPopupClose} title="Close" className="campaignPopupButtons" />
                    </div>
                  </>
                ) : (
                  <>
                    {/*{this.renderCleanupCampaignText()}*/}
                    {this.renderCovid19CampaignText()}
                    <div className="campaignPopupButtons">
                      <SectionButton
                        disabled={props.buttonsDisabled}
                        onClick={onCampaignPopupYes}
                        title="Yes"
                        className="campaignPopupButtons"
                      />
                      <SectionButton
                        disabled={props.buttonsDisabled}
                        onClick={onCampaignPopupNo}
                        title="No"
                        className="campaignPopupButton"
                      />
                    </div>
                  </>
                )}
              </SectionContent>
            </Section>
          </div>
        </div>
      </WithTransition>
    </div>;
  }
}

export { CampaignPopup };