import { Plugins } from '@capacitor/core';
import { observable, autorun } from "mobx";
import { asyncPause, safeParseJSON } from '../utils/helpers';

const { Storage } = Plugins;

export class Store {
  disposer;
  name;
  @observable data = {};
  @observable ready = false;

  constructor(name) {
    this.name = name;
    this.restoreData()
    .then(this.registerStorageListener)
    .finally(() => this.ready = true);
    return this;
  }

  restoreData = async () =>
    Storage.get({ key: this.name })
    .then(result => {
      const { value } = result;
      const data = safeParseJSON(value);
      if (data) Object.assign(this.data, data);
      return Promise.resolve();
    });

  registerStorageListener = () =>
    this.disposer = autorun(() =>
      Storage.set({
        key: this.name,
        value: JSON.stringify(this.data)
      })
    );

  isReady = async () => this.ready
    ? Promise.resolve(true)
    : asyncPause(10).then(this.isReady) ;

  dispose = () => this.disposer && this.disposer();

  clearStore = () => this.data = {};
}