import React from "react";
import { observer } from "mobx-react";
import { ExternalPage } from "../ExternalBase";
import $ from "jquery";
import { proxy } from "../../client/proxy";

@observer
class Venngo2020 extends ExternalPage {
  loaded = false;
  hostedPath = "venngo2020";

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    if (this.props.visible && !this.loaded) this.onLoad();
  }

  onLoad = () => {
    this.loaded = true;
    this.setHostedPath(this.hostedPath);
    this.onPageLoad(this.specialTreatments);
  };

  specialTreatments = () => {
    const $subDoc = $("<div></div>").append(this.hostedHtml);

    const style = `
      .keyframed {
        opacity: 0;
        position: relative;
        padding-bottom: 150px!important;
        animation: show 2s ease .5s forwards;
      }  
      @keyframes show {
        0% {
          transform: translate3d(0, 50px, 0);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    `;

    $subDoc.append(`<style>${style}</style>`);
    $subDoc.find(".uk-background-cover").addClass("keyframed");

    $subDoc.find("#venngoimg1 > img").attr("src", `${proxy.proxyUrl}media/${this.migratedHostname}/templates/yootheme/cache/mycarebaseoff-439bd745.jpeg`);

    return this.hostedHtml = $subDoc.html();
  }
}

export { Venngo2020 };