import React from "react";
import { observer } from "mobx-react";
import { Link } from "../Link";

export const FooterIcon = observer(props => {
  const Icon = props.icon;
  if (!Icon) return null;
  return (
    <Link {...props}>
      <Icon color="#fff" size={30} style={{ padding: "0 30px"}} />
    </Link>
  );
});