import { observable } from "mobx";

class ResponsiveStyles {
  viewMaxWidth = 1200;
  mobileLWidth = 960;
  mobileWidth = 768;
  mobileXSWidth = 400;

  vMaxMql = window.matchMedia(`(min-width: ${this.viewMaxWidth}px)`);
  lMql = window.matchMedia(`(max-width: ${this.mobileLWidth}px)`);
  mql = window.matchMedia(`(max-width: ${this.mobileWidth}px)`);
  xsMql = window.matchMedia(`(max-width: ${this.mobileXSWidth}px)`);

  @observable deviceDimension = {
    isViewMax: this.vMaxMql.matches,
    isLMobile: this.lMql.matches,
    isMobile: this.mql.matches,
    isXSMobile: this.xsMql.matches
  };

  constructor () {
    this.vMaxMql.addListener(event => (
      this.deviceDimension.isViewMax = event.matches
    ));
    this.lMql.addListener(event => (
      this.deviceDimension.isLMobile = event.matches
    ));
    this.mql.addListener(event => (
      this.deviceDimension.isMobile = event.matches
    ));
    this.xsMql.addListener(event => (
      this.deviceDimension.isXSMobile = event.matches
    ));
  }
}

export const responsive = new ResponsiveStyles();