import React from "react";
import { observer } from "mobx-react";
import "./styles.css";

export const Link = observer(props => (
  <a
    target={
      props._blank ? "_blank"
        : props._self ? "_self"
        : props._parent ? "_parent"
          : props._top ? "_top"
            : ""
    }
    className={`link ${props.noCursor ? "noCursor" : ""} ${props.className || ""}`}
    href={props.href}
    onClick={props.onClick}
  >
    {props.content || props.children}
  </a>
));