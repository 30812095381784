import { observer } from "mobx-react";
import React from "react";
import { Section } from "../Section";
import { SectionSubHeading } from "../SectionSubHeading";
import { SectionButton } from "../SectionButton";
import "./styles.css";
import { SectionHeading } from "../SectionHeading";
import { SectionFooter } from "../SectionFooter";
import { Link } from "../Link";
import { Tel } from "../Tel";

export const GetStartedSection = observer(props => (
  <Section dark>
    <SectionHeading>
      Find a great caregiver for <br />
      your loved one now
    </SectionHeading>

    <SectionButton
      onClick={props.onSearch}
      title="Get started"
      className="getStartedSectionButton"
    />

    <SectionFooter>
      <SectionSubHeading>
        Reach us at <Tel /> or through our <br />
        <Link href="#contact-us">contact form</Link> and one of our care advisors <br />
        will answer any questions you may have
      </SectionSubHeading>
    </SectionFooter>

  </Section>
));