import React from "react";
import { observer } from "mobx-react";
import { WebAppContainer } from "../../components/WebAppContainer";
import { webAppSrcString } from "../../config/webApp";
import { embeddedService } from "../../client/embedded-service";
import { isCrawler } from "../../config/env";
import { client } from "../../client";
import "./styles.css";

@observer
class SearchPage extends React.Component {
  webAppSrc = "";

  constructor(props) {
    super(props);
    isCrawler && console.log("is crawler:", isCrawler);
    if (!isCrawler) this.webAppSrc = webAppSrcString;
  }

  onWebAppFrameRef = frameElm => embeddedService.setFrameListener(frameElm);

  render() {
    return <div className="page search">
      <div className={`searchWaitingBanner ${client.marketplaceReady ? "" : "visible"}`}>
        Please wait, myCareBase Marketplace will be ready shortly.
      </div>
      <WebAppContainer
        onRef={this.onWebAppFrameRef}
        onLoad={this.onWebAppFrameLoad}
        src={this.webAppSrc}
      />
    </div>;
  }
}

export { SearchPage };