import { observer } from "mobx-react";
import Sidebar from "react-sidebar";
import React from "react";
import { MenuLinks } from "../PageHeader";
import "./styles.css"

export const SidebarContent = observer(props => (
  <div className="sidebarContent">
    <MenuLinks {...props} />
  </div>
));

export const SidebarMenu = observer(props => (
  <Sidebar
    shadow
    pullRight
    sidebar={<SidebarContent {...props} />}
    dragToggleDistance={80}
    open={props.sidebarOpen}
    onSetOpen={props.onSetOpen}
    contentClassName="rootContainer"
    children={props.children}
  />
));