import IdleMonitor from "./idle";
import { api } from "../core/api";
import { endpointConfig } from "../config/api";
import { getQueryParameters, isEmpty } from "../utils/helpers";

export default class PageTracker {
  session = {};
  pages = [];

  utm;
  // sourceUrl;
  currentPage;
  currentUrl;
  previousUrl;
  idleMonitor = {};

  get currentPageElm() {
    return document.getElementById(this.currentPage);
  }

  get pageTemplate() {
    return {
      sessionId: this.session.id,
      url: this.currentUrl,
      sourceUrl: this.previousUrl,
      utm: this.utm,
      lastActiveTime: this.idleMonitor.lastActiveTime,
      activeTime: this.idleMonitor.totalActiveTime,
      idleTime: this.idleMonitor.totalIdleTime,
      // groupId: null,
      // memberId: null,
      // topicId: null
    };
  }

  constructor () {
    // Set utm.
    const queryParams = getQueryParameters(location.search);
    if (queryParams.utm) this.utm = queryParams.utm;
    // Set source url.
    // const sourceUrl = queryParams.source || queryParams.source_url || queryParams.sourceUrl;
    // if (sourceUrl) {
    //   this.sourceUrl = sourceUrl;
    // }
    // Set initial source url.
    this.previousUrl = document.referrer;

    // TODO: Get existing page record back according to session;
  }

  setSession = session => {
    this.session = session;
    return this.getSessionPageActivities();
  };

  getSessionPageActivities = async () => {

  };

  switchTrackPage = async pageName => {
    if (isEmpty(this.session)) {
      return setTimeout(() => this.switchTrackPage(pageName), 250);
    }
    await this.renewCurrentPage().catch(console.warn);

    if (this.idleMonitor.tracking) this.idleMonitor.dispose();
    this.currentPage = pageName;
    if (this.currentUrl) this.previousUrl = this.currentUrl;
    this.currentUrl = window.location.href;
    this.idleMonitor = new IdleMonitor({
      element: this.currentPageElm
    });
    return await this.createOrUpdatePageActivity(pageName)
    .then(() => {
      this.idleMonitor.idleHandler = this.renewCurrentPage;
      this.idleMonitor.activeHandler = this.renewCurrentPage;
    })
    .catch(console.warn);
  };

  createOrUpdatePageActivity = async pageName => {
    const page = this.pages.find(p => p.name === pageName);
    if (!page) {
      return this.createPageActivity(pageName);
    } else {
      this.idleMonitor.setActiveTimeBase(page.activeTime);
      this.idleMonitor.setIdleTimeBase(page.idleTime);
      return this.renewPageActivity(page, pageName);
    }
  };

  createPageActivity = async (name, additionalData) => {
    if (isEmpty(this.session)) return;
    additionalData = additionalData || {};
    const page = {
      ...this.pageTemplate,
      ...additionalData
    };
    return api.POST({
      endpoint: endpointConfig.page,
      data: page
    })
    .then(response => {
      const savedPageActivity = response.data;
      if (savedPageActivity) {
        const existingPageIndex = this.pages.findIndex(p => p.name === name);
        if (existingPageIndex >= 0) this.pages.splice(existingPageIndex, 1);
        this.pages.push({
          name,
          ...savedPageActivity
        });
      }
    });
  };

  renewCurrentPage = async () => {
    const page = this.pages.find(p => p.name === this.currentPage);
    if (!page) return;
    return this.renewPageActivity(page, this.currentPage);
  };

  renewPageActivity = async (page, name) => {
    if (isEmpty(this.session)) return;
    if (isEmpty(page)) return;
    const renewedPageActivity = Object.assign(page, this.pageTemplate);
    return this.updatePageActivity(renewedPageActivity, name);
  };

  updatePageActivity = async (page, name) =>
    api.PUT({
      endpoint: endpointConfig.page_by_id(page.id),
      data: page
    })
    .then(response => {
      const updatedPageActivity = response.data;
      if (updatedPageActivity) {
        const pageIndex = this.pages.findIndex(p => p.id === updatedPageActivity.id);
        return pageIndex && (this.pages[pageIndex] = {
          name,
          ...updatedPageActivity
        });
      }
    });
}