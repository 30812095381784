import * as parser from "ua-parser-js";

export const UADetail = parser(navigator.userAgent);

export const getOS = () => ({
  cpu: UADetail.cpu,
  os: UADetail.os,
  device: UADetail.device
});

export const getBrowser = () => ({
  browser: UADetail.browser,
  engine: UADetail.engine
});