export default class IdleMonitor {
  // throttle = 100; // Interval between activity counting
  element;
  tracking = false;

  idleTimer;
  idleTimeout; // Timeout between switching idle and active
  idles = [];
  actives = [];

  activeHandler; // From constructor
  idleHandler; // From constructor

  totalActiveTimeBase = 0;
  totalIdleTimeBase = 0;

  clickHandler = e => this.handleEvent(e, "click");
  mousemoveHandler = e => this.handleEvent(e, "mousemove");
  touchmoveHandler =  e => this.handleEvent(e, "touchmove");
  keypressHandler = e => this.handleEvent(e, "keypress");
  resizeHandler = e => this.handleEvent(e, "resize");
  scrollHandler = e => this.handleEvent(e, "scroll");

  get totalIdleTime() {
    return this.idles.reduce((total, idle) => {
      if (idle) total += (idle.end || new Date().getTime()) - idle.start;
      return total;
    }, 0) + this.totalIdleTimeBase;
  }

  get totalActiveTime() {
    return this.actives.reduce((total, active) => {
      if (active) total += (active.end || new Date().getTime()) - active.start;
      return total;
    }, 0) + this.totalActiveTimeBase;
  }

  get lastActiveTime() {
    return this.actives.length > 0
      ? (this.actives[this.actives.length - 1].end ||
        this.actives[this.actives.length - 1].start)
      : 0;
  }

  get isActive() {
    return (
      this.actives.length > 0 &&
      !this.actives[this.actives.length - 1].end
    )
  }

  constructor (options) {
    const {
      element,
      idleTimeout,
      activeHandler,
      idleHandler
    } = options || {};

    if (!element) return;

    this.element = element;
    this.idleTimeout = idleTimeout || 2000;
    this.activeHandler = activeHandler;
    this.idleHandler = idleHandler;

    this.regHandlers();

    this.setActive();
    this.resetTimer();

    this.tracking = true;
  }

  regHandlers = () => {
    this.element.addEventListener("click", this.clickHandler);
    this.element.addEventListener("mousemove", this.mousemoveHandler);
    this.element.addEventListener("touchmove", this.touchmoveHandler);
    this.element.addEventListener("keypress", this.keypressHandler);
    this.element.addEventListener("resize", this.resizeHandler);
    this.element.addEventListener("scroll", this.scrollHandler);
  };

  handleEvent = (event, type) => {
    // console.log(event);
    // console.log(type);
    if (!this.isActive) return this.setActive();
    return this.resetTimer();
  };

  resetTimer = () => {
    try {
      clearTimeout(this.idleTimer);
    }
    catch (e) {
      console.warn(e);
    }
    finally {
      this.idleTimer = setTimeout(this.setIdle, this.idleTimeout);
    }
  };

  setIdle = () => {
    // console.log("actives", this.actives);
    // console.log("idles", this.idles);
    // console.log(this.totalActiveTime, this.totalIdleTime);
    if (!this.isActive && this.actives.length > 0) return; // Already idle
    const timestamp = new Date().getTime();
    if (this.actives.length > 0) {
      this.actives[this.actives.length - 1].end = timestamp;
    }
    this.idles.push({
      start: timestamp
    });
    if (typeof this.idleHandler === "function") this.idleHandler();
  };

  setActive = () => {
    // console.log("actives", this.actives);
    // console.log("idles", this.idles);
    // console.log(this.totalActiveTime, this.totalIdleTime);
    if (this.isActive) return; // Already active
    const timestamp = new Date().getTime();
    if (this.idles.length > 0) {
      this.idles[this.idles.length - 1].end = timestamp;
    }
    this.actives.push({
      start: timestamp
    });
    if (typeof this.activeHandler === "function") this.activeHandler();
  };

  setActiveTimeBase = value => this.totalActiveTimeBase = value;

  setIdleTimeBase = value => this.totalIdleTimeBase = value;

  dispose = () => {
    clearTimeout(this.idleTimer);
    this.element.removeEventListener("click", this.clickHandler);
    this.element.removeEventListener("mousemove", this.mousemoveHandler);
    this.element.removeEventListener("touchmove", this.touchmoveHandler);
    this.element.removeEventListener("keypress", this.keypressHandler);
    this.element.removeEventListener("resize", this.resizeHandler);
    this.element.removeEventListener("scroll", this.scrollHandler);
  };
}