import * as $ from "jquery";
import * as React from "react";
import { observer } from "mobx-react";
import "./styles.css";
import { SectionSubHeading } from "../SectionSubHeading";
import { SectionButton } from "../SectionButton";
import { IoIosChatbubbles, IoMdExit } from "react-icons/io";
import { FaCircle } from "react-icons/fa";
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdSend } from "react-icons/md";
import { Slide } from "react-reveal";
import { observable } from "mobx";
import ChatWidgetMessages from "../ChatWidgetMessages";
import { preventDefaultStopProp } from "../../utils/helpers";
import RandomizedContent from "../RandomizedContent";

@observer
class ChatWidget extends React.Component {
  @observable collapsed = false;

  @observable inputHeight = 0;
  maxInputHeight = 120;

  form = {};
  @observable submitDisabled = true;

  @observable inputValue = "";

  @observable exiting = false;
  @observable chatExitSendCopy = false;
  chatExitSendCopyEmail = "";

  constructor (props) {
    super(props);
    props.initChat && props.initChat();
  }

  toggleCollapse = () => this.collapsed = !this.collapsed;

  validateForm = () => {
    const formData = $('#chatWidgetForm').serializeArray();
    this.form.name = formData[0].value;
    this.submitDisabled = !this.form.name;
  };

  onFormSubmit = event => {
    preventDefaultStopProp(event);
    const { name } = this.form;
    const { onPopupConfirm } = this.props;
    return onPopupConfirm && onPopupConfirm(name);
  };

  onInputValueChange= e => {
    const { value } = e.target;
    this.inputValue = value;
    setTimeout(this.setInputHeight);
  };

  setInputHeight = () => this.inputHeight = $(this.inputHeightCalc).outerHeight();

  onMessageSend = event => {
    preventDefaultStopProp(event);
    const { onMessageSend } = this.props;
    if (onMessageSend) onMessageSend(this.inputValue)
    .then(() => this.inputValue = "");
  };

  handleEnterKey = event => {
    const { keyCode } = event;
    if (keyCode !== 13) return;
    if (!event.ctrlKey) return;
    return this.onMessageSend();
  };

  onChatDismiss = event => {
    preventDefaultStopProp(event);
    return this.exiting = true;
  };

  onChatExitCancel = event => {
    preventDefaultStopProp(event);
    return this.exiting = false;
  };

  onChatExit = event => {
    preventDefaultStopProp(event);
    const { onChatEnd } = this.props;
    return onChatEnd && onChatEnd(this.chatExitSendCopyEmail);
  };

  onChatExitSendCopyChange = event => {
    const formFields = $("#chatWidgetExitForm").serializeArray();
    this.chatExitSendCopy = formFields.some(f => f.name === "chatExitSendCopy");
    const emailField = formFields.find(f => f.name === "chatExitSendCopyEmail");
    this.chatExitSendCopyEmail = emailField && emailField.value;
  };

  render() {
    const {
      visible,
      status,
      minimized,
      agentOnline,
      agentName,
      messages,
      headingOverride,
      onPopupYes,
      onPopupNo,
      onPopupLater,
      onPopupRestore,
      onPopupReinit
    } = this.props;

    return visible && (
      <Slide bottom duration={1250}>
        <div className="chatWidget">
          {this.exiting ? (
            status === "ended" ? (
              <div className="chatWidgetEnd">
                <SectionSubHeading>
                  Thank you! Goodbye.
                </SectionSubHeading>
              </div>
            ) : (
              <div className="chatWidgetExitConfirm">
                <SectionSubHeading>
                  Are you sure you want to end the chat?
                </SectionSubHeading>
                <form onChange={this.onChatExitSendCopyChange} id="chatWidgetExitForm" className="campaignForm">
                  <div className="chatFormField campaignFormField">
                    <label className="chatExitSendCopyContainer checkboxLabel">
                      <input className="checkbox" type="checkbox" name="chatExitSendCopy" />
                      <span>Send a copy of transcript to my email</span>
                    </label>
                  </div>
                  {this.chatExitSendCopy && (
                    <div className="chatFormField campaignFormField">
                      <label>Email</label>
                      <input className="textField" type="text" name="chatExitSendCopyEmail" />
                    </div>
                  )}
                  <div className="chatFormButtons campaignFormButtons">
                    <SectionButton
                      onClick={this.onChatExit}
                      title="End"
                      className="chatFormButton"
                    />
                    <SectionButton
                      onClick={this.onChatExitCancel}
                      title="Cancel"
                      className="chatFormButton"
                    />
                  </div>
                </form>
              </div>
            )
          ) : (
            status === "popup" ? (
              minimized ? (
                <IoIosChatbubbles className="chatWidgetRestore" size={65} onClick={onPopupRestore} />
              ) : (
                <div className="chatWidgetPopup">
                  <SectionSubHeading>
                    {headingOverride ? <span>{headingOverride}</span> : <RandomizedContent component="chatPopup" />}
                  </SectionSubHeading>

                  {/*<IoIosChatbubbles className="chatWidgetIcon" size={65} />*/}
                  <img className="chatWidgetIcon chatWidgetAvatar" src={require("../../assets/chat_avatar.jpg")} alt="Care Advisor" />

                  <div className="chatWidgetButtons">
                    <SectionButton className="now" onClick={onPopupYes}>Now</SectionButton>
                    <SectionButton onClick={onPopupNo}>No thanks</SectionButton>
                    <SectionButton onClick={onPopupLater}>Later</SectionButton>
                  </div>
                </div>
              )
            ) : status === "form" ? (
              <div className="chatWidgetForm">
                <form onChange={this.validateForm} id="chatWidgetForm" className="campaignForm">
                  <div className="campaignPopupFormFields">
                    <div className="chatFormField campaignFormField">
                      <label>Your name *</label>
                      <input className="textField" type="text" name="name" />
                    </div>
                  </div>
                  <div className="chatFormButtons campaignFormButtons">
                    <SectionButton
                      disabled={this.submitDisabled}
                      onClick={this.onFormSubmit}
                      title="Enter chat"
                      className="chatFormButton"
                    />
                  </div>
                </form>
              </div>
            ) : status === "chat" ? (
              <div className={`chatWidgetMain ${this.collapsed ? "collapsed" : ""}`}>
                <div className="chatWidgetMainHeading">
                  <FaCircle className="chatWidgetMainStatus" size={12} color={agentOnline ? "#6eff00" : "#ccc"} />
                  <span>{agentName}</span>
                  <span onClick={this.toggleCollapse}>
                  {this.collapsed
                    ? <MdKeyboardArrowUp className="action chatWidgetMainClose" size={32}/>
                    : <MdKeyboardArrowDown className="action chatWidgetMainClose" size={32}/>}
                </span>
                  <span onClick={this.onChatDismiss}>
                  {/*<IoMdExit className="chatWidgetMainExit" size={25} />*/}
                  <span className="action chatWidgetMainExit">END</span>
                </span>
                </div>

                <ChatWidgetMessages messages={messages} />

                <div className="chatWidgetMainToolbar">
                <textarea
                  id="chatWidgetMainInput"
                  style={{
                    height: `${this.inputHeight}px`,
                    overflowY: this.inputHeight >= this.maxInputHeight ? "auto" : "hidden",
                    maxHeight: `${this.maxInputHeight}px`
                  }}
                  value={this.inputValue}
                  onChange={this.onInputValueChange}
                  onKeyDown={this.handleEnterKey}
                />
                  <MdSend id="chatWidgetMainSend" size={25} onClick={this.onMessageSend} />
                  <span
                    ref={elm => this.inputHeightCalc = elm}
                    className="chatWidgetMainInputHeightCalc"
                  >
                   {this.inputValue.replace(/(\r\n|\r|\n)$/i, "\n ") + " "}
                  </span>
                </div>
              </div>
            ) : status === "awayForm" ? (
              <div className="chatWidgetAgentAwayForm">
                <SectionSubHeading>
                  Currently we do not have an available Care Advisor. Do you want to chat as soon as a Care Advisor is available?
                </SectionSubHeading>
                <div className="campaignForm">
                  <div className="chatFormButtons campaignFormButtons">
                    <SectionButton
                      onClick={onPopupReinit}
                      title="Yes"
                      className="chatFormButton"
                    />
                    <SectionButton
                      onClick={onPopupNo}
                      title="No"
                      className="chatFormButton"
                    />
                  </div>
                </div>
              </div>
            ) : status === "awayConfirm" ? (
              <div className="chatWidgetEnd">
                <SectionSubHeading>
                  Thank you, we will let you know as soon as a Care Advisor is available.
                </SectionSubHeading>
              </div>
            ) : null
          )}
        </div>
      </Slide>
    );
  }
}

export default ChatWidget;