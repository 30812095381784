import React from "react";
import { observer } from "mobx-react";
import { ExternalPage } from "../ExternalBase";
import $ from "jquery";

@observer
class TermsPage extends ExternalPage {
  loaded = false;
  hostedPath = "terms-of-use";

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    if (this.props.visible && !this.loaded) this.onLoad();
  }

  onLoad = () => {
    this.loaded = true;
    this.setHostedPath(this.hostedPath);
    this.onPageLoad(this.specialTreatments)
  };

  specialTreatments = () => {
    const $subDoc = $("<div></div>").append(this.hostedHtml);

    $subDoc.find(".uk-margin-medium-top").children().css({
      margin: "15px 0",
      width: "100%"
    });
    $subDoc.find("ul, ol").css("padding-left", "90px");

    return this.hostedHtml = $subDoc.html();
  }
}

export { TermsPage };