import * as isbot from "isbot";

export const env =
  (window.location.port.match(/8000|8001/g) || window.location.hostname.match(/^testsite/g))
    ? "test"
    : (window.location.hostname.match(/^www/g) || window.location.hostname.match(/^wrapper/g))
    ? "prod"
    : (window.location.port.match(/6000|6001/g) || window.location.hostname.match(/^devsite/g))
    ? "devtest"
    : window.location.port.match(/20000|20001/g)
    ? "dev"
    : "local";


export const isCrawler = isbot(navigator.userAgent);