import React from "react";
import { observer } from "mobx-react";
import "./styles.css";

export const SectionButton = observer(props => (
  <button
    disabled={props.disabled}
    onClick={props.onClick}
    className={`sectionButton ${props.className || ""}`}
  >
    {props.title || props.children}
  </button>
));