import axios from "axios";

class Proxy {
  // cacheProxyUrl = `https://webcache.mycarebase.com`;
  proxyUrl = `https://webcache.mycarebase.com/`;
  mailerUrl = `https://webmailer.mycarebase.com/`;

  proxiedPages = [];

  getProxiedPageHtml = async url => {
    const proxiedPage = this.proxiedPages.find(p => p.url === url);
    if (proxiedPage) {
      return proxiedPage.html;
    } else {
      this.proxiedPages.push({
        url
      });
    }
    return axios.get(`${this.proxyUrl}${url}`)
    .then(response => {
      const html = response && response.data;
      if (html) this.proxiedPages.find(p => p.url === url).html = html;
      return html || `Cannot load ${url}.`
    })
  }
}

export const proxy = new Proxy();