import React from "react";
import { observer } from "mobx-react";
import { ExternalPage } from "../ExternalBase";
import $ from "jquery";
import { webAppSrcString } from "../../config/webApp";

@observer
class JobPage extends ExternalPage {
  loaded = false;
  hostedPath = "paid-caregivers";

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    if (this.props.visible && !this.loaded) this.onLoad();
  }

  onLoad = () => {
    this.loaded = true;
    this.setHostedPath(this.hostedPath);
    this.onPageLoad(this.specialTreatments)
  };

  specialTreatments = () => {
    const $subDoc = $("<div></div>").append(this.hostedHtml);

    const $regButtons = $subDoc.find("a[href='https://app.mycarebase.com']");
    $regButtons.attr("href", `${webAppSrcString}Registration?type=paid`);

    return this.hostedHtml = $subDoc.html();
  };
}

export { JobPage };