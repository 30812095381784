import React from "react";
import { observer } from "mobx-react";
import { ExternalPage } from "../ExternalBase";
import $ from "jquery";

@observer
class ConciergePage extends ExternalPage {
  loaded = false;
  hostedPath = "mycarebase-concierge";

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    if (this.props.visible && !this.loaded) this.onLoad();
  }

  onLoad = () => {
    this.loaded = true;
    this.setHostedPath(this.hostedPath);
    this.onPageLoad(this.specialTreatments)
  };

  specialTreatments = () => {
    const $subDoc = $("<div></div>").append(this.hostedHtml);

    $subDoc.find(".uk-grid-small").addClass("uk-grid");

    return this.hostedHtml = $subDoc.html();
  };
}

export { ConciergePage };