import React from "react";
import { observer } from "mobx-react";
import "./styles.css";

export const SectionFooter = observer(props => (
  <div
    style={props.style}
    className={`sectionFooter ${props.className || ""}`}
  >
    {props.children}
  </div>
));