import React from "react";
import { observer } from "mobx-react";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import { FooterIcon } from "../PageFooterIcon";
import "./styles.css";
import { Link } from "../Link";

export const PageFooter = observer(props => (
  <div className="pageFooter">
    <div className="row pageFooterIcons">
      <FooterIcon icon={FaFacebookF} _blank href="https://www.facebook.com/mycarebase/" />
      <FooterIcon icon={FaTwitter} _blank href="https://twitter.com/mycarebase"/>
      <FooterIcon icon={FaInstagram} _blank href="https://www.instagram.com/mycarebase/"/>
    </div>

    <div className="row pageFooterLinks">
      <div>
        <Link className="pageFooterText" content="About us" href="/#about-us" />
        {/* ✅TODO: wait until further notice before changing "Resources" to open this content https://www.mycarebase.com/resources because we may remove the link altogether */}
        <Link className="pageFooterText" content="Resources" href="/#resources" />
      </div>
      <div>
        <Link className="pageFooterText" content="Terms of use" href="/#terms-of-use" />
        <Link className="pageFooterText" content="Privacy policy" href="/#privacy-policy" />
      </div>
    </div>

    <div className="row pageFooterCopyRight">
      <span className="pageFooterText">
        © Copyright 2020 myCareBase Solutions Inc.
      </span>
    </div>
  </div>
));