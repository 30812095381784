import React from "react";
import { observer } from "mobx-react";
import { computed, observable } from "mobx";
import { client } from "../../client";
import { asyncPause } from "../../utils/helpers";
import { randomItems } from "../../config/abTests";

@observer
class RandomizedContent extends React.Component {
  @observable component;
  @observable randomizedItems = [];
  @observable finalItem;

  @computed get override() {
    return client.getSessionRandomItem(this.component) || {};
  }

  constructor(props) {
    super(props);
    this.component = props.component;
    this.getRandomizedItem(this.component)
    .then(this.getRandomizedFinalItem)
    .then(this.reportFinalizedItem);
  }

  getRandomizedItem = async component => {
    if (!client.sessionRandomItemsReady) {
      await asyncPause(10);
      return this.getRandomizedItem(component);
    }
    this.randomizedItems = randomItems.filter(i => i.component === component);
    return this.randomizedItems;
  };

  getRandomizedFinalItem = randomItems => {
    let sumOfWeights = randomItems.reduce((a, i) => a + i.weight, 0);
    const getRandom = () => {
      const random = Math.floor(Math.random() * (sumOfWeights + 1));
      for (const item of randomItems) {
        sumOfWeights -= item.weight;
        if (random >= sumOfWeights) return item;
      }
    };
    const getOverride = () => this.randomizedItems.find(i => i.id === this.override.id);
    const override = getOverride();
    const finalItem = override
      ? override
      : getRandom();
    this.finalItem = finalItem;
    return finalItem;
  };

  reportFinalizedItem = finalItem => {
    if (!finalItem) return client.onRandomizerLoad("default");
    return client.onRandomizerLoad(finalItem);
  };

  render() {
    return this.finalItem
      ? <span id={`_randomized_${this.component}`} dangerouslySetInnerHTML={{ __html: this.finalItem.innerHTML }}/>
      : <span id={`_randomized_${this.component}`}>{this.props.children || this.props.default}</span>;
  }
}

export default RandomizedContent;