import React from "react";
import { observer } from "mobx-react";
import "./styles.css";
import { SectionTileIcon } from "../SectionTileIcon";
import { responsive } from "../../config/styles/responsive";
import { computed, decorate } from "mobx";
import { Link } from "../Link";

export const placeholderImage = {
  src: require("../../assets/placeholder.png"),
  width: "200px",
  height: "100px"
};

export const placeholderImageLarge = {
  src: require("../../assets/placeholder.png"),
  width: "200px",
  height: "150px"
};

export const placeholderImageXLarge = {
  src: require("../../assets/placeholder.png"),
  get width() {
    return responsive.deviceDimension.isXSMobile ? "250px" : "300px";
  },
  height: "200px"
};
decorate(placeholderImageXLarge, {
  width: computed
});

export const SectionTile = observer(props => (
  <div className={`sectionTile ${props.align ? props.align : ""} ${props.className || ""}`}>
    {props.image && (
      <Link
        href={props.image.useDefaultHref ? props.href : props.image.href}
        _blank={props.image.useDefaultHref ? props._blank : props.image._blank}
        noCursor={props.noCursor}
      >
        <img
          alt={props.image.alt}
          src={props.image.src}
          style={{
            width: props.image.width || "auto",
            height: props.image.height || "auto"
          }}
        />
      </Link>
    )}
    {props.icon && (() => {
      const { icon, href, color, size } = props.icon;
      return <SectionTileIcon size={size} icon={icon} href={href} color={color} />;
    })()}
    <Link
      className="sectionTileTitle"
      href={props.href}
      _blank={props._blank}
      noCursor={props.noCursor}
    >
      {props.heading || props.title}
    </Link>
    <span className="sectionTileText">
      {props.content || props.children}
    </span>
  </div>
));