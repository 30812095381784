import React from "react";
import { observer } from "mobx-react";
import { ExternalPage } from "../ExternalBase";
import $ from "jquery";

@observer
class FindCaregiverPage extends ExternalPage {
  loaded = false;
  hostedPath = "family-caregivers";

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    if (this.props.visible && !this.loaded) this.onLoad();
  }

  onLoad = () => {
    this.loaded = true;
    const conciergePath = location.pathname.replace("/", "").split("/")[1];
    if (conciergePath === "mycarebase-concierge") {
      return location.href = `/${conciergePath}`;
    }
    this.setHostedPath(this.hostedPath);
    this.onPageLoad(this.specialTreatments)
  };

  specialTreatments = () => {
    const $subDoc = $("<div></div>").append(this.hostedHtml);

    $subDoc.find(".el-item").css("margin", "15px 0");
    $subDoc.find(".el-content.uk-button-default").css("width", "unset");

    const learnMoreButton = $subDoc.find("a[href$='https://app.mycarebase.com/visitor\?source=https://www.mycarebase.com']");
    learnMoreButton.removeAttr("href");
    learnMoreButton.attr("onclick", "parent.client.switchToSearch()");

    const conciergeButton = $subDoc.find("a[href$='#mycarebase-concierge']");
    conciergeButton.removeAttr("href");
    conciergeButton.attr("onclick", "parent.location.hash = 'mycarebase-concierge'");

    const signUpButton = $subDoc.find("a[href='#care-concierge-service']");
    signUpButton.removeAttr("href");
    signUpButton.attr("onclick", "parent.window.open('//app.mycarebase.com/Registration?type=family')");

    $subDoc.find(`[class*="uk-width-1-2"]`)
    .parent()
    .append(
      `<style type="text/css">@media (min-width: 768px) { .uk-width-1-2\\@m { padding: 0 }}</style>`
    );

    $subDoc.find(".uk-button").css("padding", "0 40px");
    return this.hostedHtml = $subDoc.html();
  };
}

export { FindCaregiverPage };