import { observer } from "mobx-react";
import React from "react";
import { Section } from "../Section";
import { SectionTile } from "../SectionTile";
import { SectionContent } from "../SectionContent";
import "./styles.css";
import { FaChartLine, FaMoneyCheckAlt } from "react-icons/fa";
import { GoTasklist } from "react-icons/go";
import { MdPhoneIphone } from "react-icons/md";

export const AdvantagesSection = observer(props => (
  <Section dark heading="Our advantages">
    <SectionContent wrap className="advantagesSectionContent">

      <SectionTile noCursor icon={{ icon: FaMoneyCheckAlt }} heading="Families save money">
        Our fees and caregiver rates are lower than
        traditional agencies, meaning more
        money stays in your wallet
      </SectionTile>

      <SectionTile noCursor icon={{ icon: FaChartLine }} heading="Caregivers earn more">
        More of your money goes directly to
        the caregivers so they can earn a
        reasonable living wage
      </SectionTile>

      <SectionTile noCursor icon={{ icon: GoTasklist }} heading="You're in control">
        You choose the caregiver and set out
        the tasks that you would like performed
      </SectionTile>

      {/*<SectionTile noCursor icon={{ icon: MdPhoneIphone }} heading="Direct and open communication">*/}
      <SectionTile noCursor icon={{ icon: MdPhoneIphone }} heading="Virtually manage from anywhere">
        Use our app to communicate with caregivers and
        monitor their performance from wherever you are
        {/*Use our app to stay connected with your*/}
        {/*caregiver and monitor their performance*/}
      </SectionTile>

    </SectionContent>
  </Section>
));