import { asyncPause, isEmpty, minutesToMilli } from "./utils/helpers";
import { api } from "./core/api";
import { endpointConfig } from "./config/api";
import { getGeoLocation } from "./trackers/geolocation";
import { getIpAddress } from "./trackers/ipAddress";
import { getBrowser, getOS } from "./trackers/userAgent";
import IdleMonitor from "./trackers/idle";
import PageTracker from "./trackers/page";
import { observable } from "mobx";

export const SessionExpiryTimeout = minutesToMilli(2);

export default class Tracker {
  deviceId;
  userId;
  os;
  browser;
  idleMonitor = {};
  ipAddresses = {};
  geoLocation = {};
  pageTracker = {};
  abTestData = {};

  @observable session = {};
  sessionReportTimeout = 10000; // 10 seconds between each server ping.
  sessionReportTimer;
  sessionExpiryTimeout = SessionExpiryTimeout;

  initialized = false;
  preInitQueue = [];

  get sessionTemplate() {
    return {
      userId: this.userId,
      regDeviceId: this.deviceId,
      abTestData: JSON.stringify(this.abTestData),
      regIpAddr: JSON.stringify(this.ipAddresses),
      geolocation: JSON.stringify(this.geoLocation),
      os: JSON.stringify(this.os),
      browser: JSON.stringify(this.browser),
      viewport: `${window.innerWidth} x ${window.innerHeight}`,
      lastActiveTime: this.idleMonitor.lastActiveTime,
      activeTime: this.idleMonitor.totalActiveTime,
      idleTime: this.idleMonitor.totalIdleTime
    };
  };

  constructor (clientInfo) {
    if (isEmpty(clientInfo) || !clientInfo.deviceId) {
      throw { message: "deviceId is required to initialize a tracking session."};
    }
    this.deviceId = clientInfo.deviceId;
    this.userId = clientInfo.userId;
    this.idleMonitor = new IdleMonitor({
      element: clientInfo.trackElement,
      activeHandler: this.reactiveSession,
    });
    this.pageTracker = new PageTracker();

    // this.parseUA()
    // .then(this.getIpAndGeo)
    // .then(this.doneInitialize)
    // .catch(console.warn);
  };

  hasSession = async () => {
    // if (isEmpty(this.session) || !this.session.id) {
    //   await asyncPause(250);
    //   return this.hasSession();
    // }
    return true;
  };

  doneInitialize = async () => {
    this.initialized = true;
    await this.startOrUpdateSession().catch(console.warn);
    // if (this.preInitQueue.length > 0) {
    //   for (const activity of this.preInitQueue) {
    //     await this.addActivity(activity)
    //     .then(() =>
    //       this.preInitQueue.splice(this.preInitQueue.indexOf(activity), 1)
    //     );
    //   }
    // }
  };

  parseUA = async () => {
    this.os = getOS();
    this.browser = getBrowser();
    return Promise.resolve();
  };

  getIpAndGeo = async () => {
    const ipAddresses = await getIpAddress().catch(console.warn);
    if (!isEmpty(ipAddresses)) this.ipAddresses = ipAddresses;

    return await getGeoLocation(
      this.geoLocation,
      this.ipAddresses.ipv4 || this.ipAddresses.ipv6);
  };

  getLastSession = async () => {
    if (!this.initialized) return;
    return api.GET({
      endpoint: endpointConfig.session_by_device_id(this.deviceId)
    })
    .then(response => response.data || {});
  };

  isExpiredSession = session => {
    if (!session) return true;
    if (session.isComplete) return true;
    if (isNaN(session.lastActiveTime)) {
      // TODO: This needs to be a lastActiveTimeLong to prevent iOS safari error.
      session.lastActiveTime = new Date(session.lastActiveTime).getTime() || 0;
    }
    return new Date().getTime() - session.lastActiveTime >= this.sessionExpiryTimeout;
  };

  startOrUpdateSession = () => {
    // if (!this.initialized) {
    //   return this.preInitQueue.push(data);
    // }
    if (!this.initialized) return;
    return this.getLastSession()
    .then(async session => {
      if (this.isExpiredSession(session)) {
        if (!isEmpty(session)) {
          await this.completeSession(session).catch(console.warn);
        }
        return this.startNewSession();
      }
      this.idleMonitor.setActiveTimeBase(session.activeTime);
      this.idleMonitor.setIdleTimeBase(session.idleTime);
      return this.renewSession(session)
    })
    .then(this.startSessionReportTimer);
  };

  checkCompletedSession = async () => {
    if (!this.initialized) return;
    if (isEmpty(this.session)) return;
    return (() => this.isExpiredSession(this.sessionTemplate)
        ? this.completeSession()
        : this.renewSession(this.session)
    )().catch(console.warn);
  };

  reactiveSession = () => {
    if (this.session.isComplete) {
      return this.startNewSession()
      .then(this.startSessionReportTimer)
      .catch(console.warn);
    }
    return this.checkCompletedSession();
  };

  startSessionReportTimer = () =>
    this.sessionReportTimer =
      setInterval(this.checkCompletedSession, this.sessionReportTimeout);

  startNewSession = async () => {
    if (!this.initialized) return;
    const session = { ...this.sessionTemplate };
    return api.POST({
      endpoint: endpointConfig.session,
      data: session
    })
    .then(response => {
      const session = response.data || {};
      // console.log(session);
      return this.setSession(session);
    });
  };

  renewSession = async session => {
    if (!this.initialized) return;
    if (isEmpty(session)) return;
    const renewedSession = Object.assign(session, this.sessionTemplate);
    return this.updateSession(renewedSession);
  };

  completeSession = async inputSession => {
    if (!this.initialized) return;
    const session = inputSession || this.session;
    if (isEmpty(session)) return;
    clearInterval(this.sessionReportTimer);
    const completedSession = Object.assign(session, inputSession || this.sessionTemplate);
    completedSession.isComplete = 1;
    return this.updateSession(completedSession);
  };

  updateSession = async session =>
    api.PUT({
      endpoint: endpointConfig.session_by_id(session.id),
      data: session
    })
    .then(response => {
      const updatedSession = response.data || session;
      // console.log(updatedSession);
      return this.setSession(updatedSession);
    });

  setSession = session => {
    this.session = session;
    this.pageTracker && this.pageTracker.setSession(session);
  }
}