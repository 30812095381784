import { api } from "../core/api";
import { ipGeolocationApiUrl } from "../config/ipGeolocation";

export const getGeoLocation = async (store, ip) => {
  if (!store) return;

  const useIp = () => {
    if (!ip) return Promise.reject("no ip address provided to lookup for geo location");
    return api.GET({
      endpoint: ipGeolocationApiUrl(ip)
    })
    .then(response => {
      const ipData = response.data;
      if (ipData) store.ipGeolocation = ipData;
      return store;
    });
  };

  const parsePosition = position => {
    if (position) {
      store.coords = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        altitude: position.coords.altitude,
        altitudeAccuracy: position.coords.altitudeAccuracy,
        accuracy: position.coords.accuracy,
        heading: position.coords.heading,
        speed: position.coords.speed
      };
      store.timestamp = position.timestamp;
    }
  };

  const asyncGetLocationNative = () => setTimeout(() =>
    getGeoLocationNative().then(parsePosition).catch(console.warn)
  );

  return await useIp()
  .then(asyncGetLocationNative)
  .catch(console.warn);

  // return await getGeoLocationNative()
  // .then(parsePosition)
  // .then(useIp)
  // .catch(err => {
  //   console.warn(err);
  //   console.warn("native geo api denied or not supported, using ip address instead.");
  //   useIp();
  //   return Promise.resolve(store);
  // });
};

export const getGeoLocationNative = async () => {
  if (navigator.geolocation) {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(position => resolve(position), reject)
    );
  }
};