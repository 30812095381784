import { observer } from "mobx-react";
import React from "react";
import { Section } from "../Section";
import "./styles.css";
import { SectionButton } from "../SectionButton";
import { Link } from "../Link";
import { Tel } from "../Tel";
import { SectionText } from "../SectionText";
import { client } from "../../client";
import RandomizedContent from "../RandomizedContent";

export const CriteriaSection = observer(props => (
  <Section
    className="criteriaSection"
    backgroundImage={`
      linear-gradient(to bottom, #ffffff00, #66666633),
      url(${require("../../assets/criteria_bg.jpg")})
    `}
  >
    <form id="criteriaForm">
      <span className="criteriaFormHeading">
        {/*See how easy it is to find reliable home care for your loved one*/}
        <RandomizedContent component="searchBoxTitle" />
      </span>
      <div className="criteriaFormFields">

        <div className="criteriaFormField">
          <label>Province</label>
          <select id="provinces" defaultValue="BC" onChange={props.onProvinceChange}>
            {props.provinces.map(province => (
              <option
                id={province.name}
                key={province.name}
                value={province.name}
              >
                {province.placeholder || province.display}
              </option>
            ))}
          </select>
        </div>

        <div className="criteriaFormField">
          <label>City</label>
          <select id="cities" onChange={props.onCityChange}>
            {props.cities.map(city => (
              <option key={city.name} value={city.name}>
                {city.placeholder || city.display}
              </option>
            ))}
          </select>
        </div>

        <Link href="#search" onClick={() => client.switchToSearch()} content="Show more search criteria" />
      </div>
      <SectionButton onClick={props.onSearch} title="Search" className="criteriaFormButton" />
      <span className="criteriaFormFooter">
        Are you a caregiver looking for jobs?
        <Link href="#paid-caregivers" content="Get started here" />
      </span>
    </form>

    <SectionText className="criteriaSectionCTA">
      <RandomizedContent component="headline"/><br />
      Call <Tel /> or use our <Link href="#contact-us">contact form</Link>
    </SectionText>
  </Section>
));