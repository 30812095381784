import { observer } from "mobx-react";
import * as React from "react";
import { FaEllipsisH } from "react-icons/fa";
import "./styles.css";

@observer
class ChatWidgetMessageItem extends React.Component {
  render() {
    const {
      sender,
      isSelf,
      text,
      date,
      loading
    } = this.props;

    return <div className={`chatWidgetMessage ${isSelf ? "self" : ""}`}>
      <span className="chatWidgetMessageSender">{sender}:&nbsp;</span>
      <span className="chatWidgetMessageText">{text}</span>
      {loading ? (
        <FaEllipsisH className="chatWidgetMessageLoading" size={12} />
      ) : (
        <span className="chatWidgetMessageDate">{date && date.toLocaleString()}</span>
      )}
    </div>
  }
}

export default ChatWidgetMessageItem;