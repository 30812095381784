import React from "react";
import { observer } from "mobx-react";
import "./styles.css";

export const SectionText = observer(props => (
  <span
    style={props.style}
    className={`sectionText ${props.align || ""} ${props.className || ""}`}
  >
    {props.content || props.children}
  </span>
));