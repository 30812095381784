import React from "react";
import { observer } from "mobx-react";
import "./styles.css";

export const WebAppContainer = observer(props => (
  <iframe
    title="webapp"
    ref={props.onRef}
    onLoad={props.onLoad}
    id="webAppFrame"
    src={props.src}
  />
));