import React from "react";
import { observer } from "mobx-react";
import { Section } from "../../components/Section";
import { SectionHeading } from "../../components/SectionHeading";
import { SectionContent } from "../../components/SectionContent";
import { SectionTile } from "../../components/SectionTile";
import { SectionFooter } from "../../components/SectionFooter";
import { SectionText } from "../../components/SectionText";
import { PageFooter } from "../../components/PageFooter";
import { Covid19Form } from "../../components/Covid19Form";
import "./styles.css";
import { client } from "../../client";
import { Tel } from "../../components/Tel";
import { Link } from "../../components/Link";

@observer
class Covid19Page extends React.Component {
  // @observable headingBannerHeight;

  render() {
    return <div className="page covid19">
      <Section className="covid19HeadingSection">
        <div
          className="bannerBackground"
          style={{ backgroundImage: `url(${require("../../assets/banner.jpg")})` }}
        />
        <SectionHeading className="sectionBannerTitle">
          <span className="sectionBannerText">
            {/*Home care during the COVID-19 crisis*/}
            Get home care during the COVID-19 crisis
          </span>
        </SectionHeading>
        <SectionHeading>
          <span className="sectionHeadingText">
            If you are someone who had home healthcare needs prior to the COVID-19 crisis,
            chances are your basic care needs have not changed.
          </span>
        </SectionHeading>
        <SectionHeading>
          <span className="sectionHeadingText">
            Alternatively, you may be a family caregiver and finding it especially hard juggling everything
            in addition to caring for loved ones.
            You are probably wondering how to get help without over-exposing yourself and your loved ones.
          </span>
        </SectionHeading>
        {/*<style>{`.covid19HeadingSection > .container { min-height: ${this.headingBannerHeight}px }`}</style>*/}
        {/*<img*/}
          {/*src={require("../../assets/banner.jpg")}*/}
          {/*alt="Contact myCareBase to receive continuity of home healthcare for seniors and relieve family caregivers despite the coronavirus (COVID-19) outbreak."*/}
        {/*/>*/}
        <SectionFooter />
      </Section>

      <Section dark className="covid19ServicesSection">
        <SectionHeading>
          <span className="sectionHeadingText">
            We have adapted our services to address the changing circumstances around us,
            so that care recipients can have their mandatory care needs addressed
            but not unnecessarily prolong the period of exposure.
          </span>
          <span className="sectionHeadingText">
            Our flex plan includes the following services:
          </span>
        </SectionHeading>
        <SectionContent wrap className="covid19SectionContent">
          <SectionTile
            noCursor
            // className="covid19ServiceTile"
            image={{
              alt: "myCareBase can provide in-home personal care during the coronavirus (COVID-19) pandemic.",
              width: "300px",
              height: "auto",
              src: require("../../assets/mycarebase_personal_care_in_home_coronavirus_covid-19.jpg" ),
            }}
            heading="Hygiene and personal care"
          />
  
          <SectionTile
            noCursor
            // className="covid19ServiceTile"
            image={{
              alt: "myCareBase can provide reminders and assist with taking medication during the coronavirus (COVID-19) pandemic.",
              width: "300px",
              height: "auto",
              src: require("../../assets/mycarebase_medication_reminders_assistance_coronavirus_covid-19.jpg" ),
            }}
            heading="Medication reminders and assistance"
          />
  
          <SectionTile
            noCursor
            // className="covid19ServiceTile"
            image={{
              alt: "myCareBase helps with grocery shopping, delivery and drop-off at your door during the coronavirus (COVID-19) pandemic.",
              width: "300px",
              height: "auto",
              src: require("../../assets/mycarebase_grocery_shopping_delivery_drop_off_coronavirus_covid-19.jpg" ),
            }}
            heading="Grocery delivery and meal prep"
          />

          <SectionTile
            noCursor
            // className="covid19ServiceTile"
            image={{
              alt: "myCareBase can help with basic house chores and cleaning during the coronavirus (COVID-19) pandemic.",
              width: "300px",
              height: "auto",
              src: require("../../assets/mycarebase_house_chores_cleaning_coronavirus_covid-19.jpg" ),
            }}
            heading="Basic house chores and cleaning"
          />

          <SectionTile
            noCursor
            // className="covid19ServiceTile"
            image={{
              alt: "myCareBase can provide continuity of home care including phone check-ups during the coronavirus (COVID-19) pandemic.",
              width: "300px",
              height: "auto",
              src: require("../../assets/mycarebase_home_care_visit_phone_check_up_coronavirus_covid-19.jpg" ),
            }}
            heading="Care continuity and phone check-ups"
          />

          <SectionTile
            noCursor
            // className="covid19ServiceTile"
            image={{
              alt: "myCareBase Care Concierge is just a call away to get home care help during the coronavirus (COVID-19) pandemic.",
              width: "300px",
              height: "auto",
              src: require("../../assets/mycarebase_care_concierge_help_coronavirus_covid-19.jpg" ),
            }}
            heading="Care Concierge services"
          />
        </SectionContent>
        <SectionFooter />
      </Section>

      <Section className="covid19FormSection">
        <SectionHeading>
          <span className="sectionHeadingText">
            Our caregiver matching process still allows the client to have the ultimate choice of their preferred caregiver,
            and all caregivers will be asked to use personal protective gear in the client’s home.
          </span>
          <SectionText style={{ display: "block" }}>
            <span className="contactText">
              Call <Tel /> or use our <Link href="/contact-us">contact form</Link>.
            </span>
          </SectionText>
        </SectionHeading>
        {/*<SectionContent>*/}
          {/*<Covid19Form*/}
          {/*  onFormSubmit={client.onCampaignPopupFormSubmit}*/}
          {/*  buttonsDisabled={client.campaignPopupSubmitting}*/}
          {/*  showFinish={client.campaignPopupShowFinish}*/}
          {/*/>*/}
        {/*</SectionContent>*/}
        <SectionFooter />
      </Section>

      <Section dark className="covid19LinkSection">
        <SectionHeading>
          <span className="sectionHeadingText">
            Here are some useful tips and information on how to protect yourself and your family from COVID-19:
          </span>
        </SectionHeading>
        <SectionContent className="covid19LinkTiles">
          <SectionTile
            className="covid19LinkTile"
            image={{
              src: require("../../assets/canada_coronavirus_covid-19_being_prepared.jpg"),
              alt: "Government of Canada website about coronavirus disease (COVID-19) outbreak, symptoms and treatment, and being prepared.",
              width: "300px",
              height: "auto",
              useDefaultHref: true
            }}
            heading={`Coronavirus disease \n(COVID-19): Being prepared`}
            _blank
            href="//www.canada.ca/en/public-health/services/diseases/2019-novel-coronavirus-infection/being-prepared.html"
          >
            The Government of Canada has published a website to keep Canadians informed about the outbreak,
            symptoms and treatment, and being prepared.
          </SectionTile>

          <SectionTile
            className="covid19LinkTile"
            image={{
              src: require("../../assets/british_columba_coronavirus_covid-19_support_app_self-assessment_tool.jpg"),
              alt: "Province of BC coronavirus disease (COVID-19) Support App and Self-assessment Tool.",
              width: "300px",
              height: "auto",
              useDefaultHref: true
            }}
            heading="COVID-19 BC Support App and Self-Assessment Tool"
            _blank
            href="//bc.thrive.health"
          >
            The Province of BC has established a self-assessment online tool
            and app for those who are showing symptoms,
            and information about what to do.
          </SectionTile>
        </SectionContent>
        <SectionFooter />
      </Section>

      <PageFooter />
    </div>;
  }
}

export { Covid19Page };