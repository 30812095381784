import React from "react";
import { observer } from "mobx-react";
import { ExternalPage } from "../ExternalBase";

@observer
class AboutPage extends ExternalPage {
  loaded = false;
  hostedPath = "about-us";

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    if (this.props.visible && !this.loaded) this.onLoad();
  }

  onLoad = () => {
    this.loaded = true;
    this.setHostedPath(this.hostedPath);
    this.onPageLoad(this.specialTreatments)
  };
}

export { AboutPage };