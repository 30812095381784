import { preventDefaultStopProp } from "../utils/helpers";
import { client } from "./index";

export class EmbeddedService {
  frame;

  constructor() {
    client.regPostFrameMessageMethod(data => this.sendFrameMessage(data));
    client.regGetFrameSrcMethod(() => this.getFrameSrc());
    client.regSetFrameSrcMethod(href => this.setFrameSrc(href));
  }

  getFrameSrc = () => this.frame && this.frame.src;

  setFrameSrc = href => this.frame && (this.frame.src = href);

  setFrameListener = async frame => {
    this.frame = frame;
    return window.addEventListener("message", this.processFrameMessages);
  };

  sendFrameMessage = data => {
    const frameWindow = this.frame && this.frame.contentWindow;
    if (!frameWindow) return;
    return frameWindow.postMessage({
      fromMCB: true,
      timestamp: new Date().getTime(),
      data
    }, "*");
  };

  sendHostInformation = () => this.sendFrameMessage({
    parentOrigin: `${window.location.protocol}//${window.location.host}`
  });

  processFrameMessages = event => {
    preventDefaultStopProp(event);
    const { data } = event;
    if (!data || !data.fromMCB) return;
    const messageData = data.data;
    console.log("new data from frame:", messageData);
    return this.handleFrameMessage(messageData);
  };

  handleFrameMessage = message => {
    if (typeof message !== "object") return;
    const types = Object.keys(message);
    for (const type of types) {
      if (!type) continue;
      this.processFrameData(type, message[type]);
    }
  };

  processFrameData = (type, data) => {
    if (type === "getOrigin" && !!data) {
      this.sendHostInformation();
    }
    if (type === "deviceId") {
      client.store.deviceId = data;
    }
    if (type === "clientId") {
      client.store.clientId = data;
    }
    if (type === "user") {
      client.store.user = data;
    }
    if (type === "oauth") {
      client.store.OAuthData = data;
    }
    if (type === "isVisitor") {
      client.store.isVisitor = !!data;
    }
    if (type === "viewGroup") {
      client.store.currentViewGroup = data;
    }
    if (type === "defaultGroup") {
      client.store.defaultGroup = data;
    }
    if (type === "viewShortlistTopic") {
      client.store.viewShortlistTopic = data;
    }
    // Client profile is a memory state not persistent (criteria fields)
    if (type === "profile") {
      client.profile = data;
    }
    if (type === "profileData") {
      client.profileData = data;
    }
    // Marketplace ready flag for queued search to continue
    if (type === "marketplaceReady") {
      client.marketplaceReady = true;
    }
    // Search result caregiver profiles from web app
    if (type === "searchResults") {
      client.searchResults = data;
    }
    // Initialization state of web app
    if (type === "status") {
      client.webAppStatus = data;
    }

    // Proactive chat agent online
    if (type === "proactiveChatOnline") {
      client.chatPopupVisible = true;
    }
    // Proactive chat topic
    if (type === "proactiveChatTopic") {
      client.chatTopic = data;
    }
    // Proactive chat messages
    if (type === "proactiveChatMessages") {
      client.chatMessages = data;
    }
    // Proactive chat agent online
    if (type === "proactiveChatAgent") {
      client.onChatAgentOnline(data);
    }

    if (type === "refreshFailure") {
      setTimeout(client.execVisitorLogin);
    }
  }
}

export const embeddedService = new EmbeddedService();