import React from "react";
import { observer } from "mobx-react";
import { SectionHeading } from "../SectionHeading";
import "./styles.css";

export const Section = observer(props => (
  <div
    style={{
      ...props.dark && { backgroundColor: "#f5f5f5" },
      ...props.backgroundImage && { backgroundImage: props.backgroundImage }
    }}
    className={`section ${props.className || ""}`}
  >
    <div className="container">
      {props.heading && <SectionHeading content={props.heading} />}
      {props.children}
    </div>
  </div>
));