import Axios from "axios";
import { baseURL, serverConfig } from "../config/api";

const config = {
  baseURL,
  headers: serverConfig.defaultHeaders,
  auth: serverConfig.auth
};

class Api {
  constructor() {
    this.axios = Axios.create(config);
  }

  async = (method, options) =>
    this.axios({
      method: method.toLowerCase(),
      url: options.endpoint,
      data: options.data,
      responseType: options.responseType,
      headers: {
        ...options.headers
      }
    });

  GET = options => this.async("GET", options);

  POST = options => this.async("POST", options);

  PATCH = options => this.async("PATCH", options);

  PUT = options => this.async("PUT", options);

  DELETE = options => this.async("DELETE", options);
}

export const api = new Api();
