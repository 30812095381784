import { observer } from "mobx-react";
import React from "react";
import { Section } from "../Section";
import { SectionTile } from "../SectionTile";
import { SectionContent } from "../SectionContent";
import "./styles.css";
import { responsive } from "../../config/styles/responsive";

export const ResourcesSection = observer(props => {
  const tileAlign = responsive.deviceDimension.isMobile ? "" : "left";
  return <Section heading="Resources">
    <SectionContent wrap className="resourcesSectionContent">
      <SectionTile
        align={tileAlign}
        image={{
          alt: "myCareBase senior home care resources and information for family caregivers",
          src: require("../../assets/blog.jpg"),
          useDefaultHref: true
        }}
        // TODO: "Blog" should show the "#resources" content, which Stephanie may rename to "#blog"
        // href="#blog"
        href="#resources"
        heading="Blog"
      >
        Check out our latest blog articles
        containing valuable information
        for family caregivers
      </SectionTile>

      <SectionTile
        align={tileAlign}
        image={{
          alt: "Dementia care training and certification for caregivers from Personalized Dementia Solutions",
          src: require("../../assets/dementia.jpg" ),
          useDefaultHref: true
        }}
        _blank
        href="//dementiasolutions.ca/cdcp-mcb/"
        heading="Dementia Care Certification"
      >
        We have partnered with a dementia
        expert to offer professional caregivers
        a special rate for this dementia care
        certification program
      </SectionTile>

      <SectionTile
        align={tileAlign}
        image={{
          alt: "myCareBase Care Concierge to help with home care coordination",
          src: require("../../assets/concierge.jpg" ),
          useDefaultHref: true
        }}
        href="#mycarebase-concierge"
        heading="Care Concierge"
      >
        We can help make appointments,
        recommend third-party services,
        and manage other tasks
      </SectionTile>
    </SectionContent>

    {/*Keep for future reference or usage*/}
    {/*<SectionFooter className="resourcesSectionFooter">*/}
      {/*<SectionButton title="View all resources" />*/}
    {/*</SectionFooter>*/}
  </Section>;
});