import { env } from "../../config/env";

export const serverConfig = {
  hostname:
    env === "dev"
      ? "leonli.jateruy.com"
      : env === "local"
      ? "devtest.mycarebase.com"
      : `${env}.mycarebase.com`,
  port: env === "dev" ? 8080 : 443,
  https: env !== "dev",

  // For backend: use this if your are running locally, comment out the lines above and enable below.

  // hostname: "localhost",
  // port: 8080,
  // https: false,

  defaultHeaders: {
    Authorization: "Basic d2ViYXBwOndlYmFwcA=="
  }
};

export const serverPortString = serverConfig.port
.toString()
.match(/^80$|^443$/g)
  ? ""
  : `:${serverConfig.port}`;

export const serverHostnameString = `${
  serverConfig.https ? "https://" : "http://"
  }${serverConfig.hostname}${serverPortString}`;

export const baseURL = serverHostnameString + "/activity";

export const endpointConfig = {
  // Maintenance status message.
  maintenance: `/maintenance_status`,

  session: "/userActivitySession",
  session_by_id: sessionId => `${endpointConfig.session}/${sessionId}`,
  session_by_device_id: deviceId => `${endpointConfig.session}/regDeviceId/${deviceId}`,

  page: "/userActivityPage",
  page_by_id: pageId => `${endpointConfig.page}/${pageId}`,
  // activities: "/userActivity",
  // activity_by_id: id => `/userActivity/${id}`
};