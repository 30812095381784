import { autorun, computed, observable, reaction, toJS, when } from "mobx";
import {
  asyncPause,
  capitalize,
  daysToMilli,
  getDisplayNameEng,
  isEmpty,
  minutesToMilli,
  validateEmail
} from "../utils/helpers";
import { Store } from "./storage";
import { webAppSrcString } from "../config/webApp";
import Tracker, { SessionExpiryTimeout } from "../tracker";
import { responsive } from "../config/styles/responsive";
import { env, isCrawler } from "../config/env";
import * as axios from "axios";
import { webAppBackendConfig, webAppBackendSrcString } from "../config/webAppBackend";
import jstz from "jstz";
import * as pageHelmets from "../config/pageHelmets";
import * as $ from "jquery";
import { toAbTestData } from "../config/abTests";

class Client {
  storage;
  tracker;
  get sessionExpiryTimeout() {
    return (this.tracker || {}).sessionExpiryTimeout || 0;
  };

  @observable profile;
  @observable profileData;
  @observable searchResults;

  @observable selectedProvince;
  @observable selectedTerritory;
  @observable selectedCity;
  @observable webAppStatus = {};
  @observable marketplaceReady = false;
  @observable gotOAuthData = false;

  @observable campaignPopupVisible = false;
  @observable campaignPopupShowForm = false;
  @observable campaignPopupShowFinish = false;
  @observable campaignPopupSubmitting = false;
  popupDelayAfterSessionInit = 2800; // Minus 200 millisec to compensate session initialization network delay

  @observable chatPopupVisible = false;
  @observable chatPopupStatus = "popup";
  @observable chatPopupMinimized = false;
  @observable chatTopic = {};
  @observable chatMessages = [];
  @observable chatAgentName = "";
  @observable chatAgentOnline = false;
  @observable chatHide = false;
  // @observable showTitleFlash = false;
  @observable chatMessageUnread = 0;
  chatLastMessagesCount = 0;

  // currentCampaignId = "cleaning_2020-03";
  currentCampaignId = "adhoc_covid19_2020-03";

  scrollingPos = {};

  defaultProvince = "BC";

  loadingPickerOptions = [{
    name: "loading",
    display: "Please wait..."
  }];

  get rootContainerElm() {
    return document.getElementsByClassName("rootContainer")[0];
  };
  get faviconElm() {
    return $("#favicon");
  };
  prevPage;
  currentPage;
  get currentTitle() {
    const helmet = pageHelmets.find(
      hm => hm.name === this.currentPage
    ) || pageHelmets[0]; // Defaults to home helmet if not specified.
    return helmet.title;
  };
  get abTestData() {
    return toAbTestData((this.sessionRandomItems || {}).items);
  };
  get isDevUser() {
    if (!this.tracker) return;
    const { isDevUser } = this.tracker.session;
    console.log("isDevUser", isDevUser);
    return isDevUser;
  };
  get isDevUserEnv() {
    return this.isDevUser && env.match(/prod/g);
  }

  @computed get store() {
    return this.storage.data;
  };

  @computed get webAppInitialized() {
    return this.webAppStatus && this.webAppStatus["initialized"];
  }
  @computed get deviceId() {
    return this.store.deviceId;
  };
  @computed get clientId() {
    return this.store.clientId;
  };
  @computed get user() {
    return this.store.user || {};
  };
  @computed get isVisitor() {
    return this.store.isVisitor || (
      this.user &&
      this.user.userName &&
      this.user.userName.match(/{visitor}/g)
    );
  }
  @computed get OAuthData() {
    return this.store.OAuthData;
  };
  @computed get visitorOAuthData() {
    return this.store.visitorOAuthData || {};
  }
  @computed get currentViewGroup() {
    return this.store.currentViewGroup || {};
  }
  @computed get defaultGroup() {
    return this.store.defaultGroup || {};
  }
  @computed get currentShortlistTopic() {
    return this.store.viewShortlistTopic || {};
  }
  @computed get member() {
    const members = this.currentViewGroup.members || [];
    return members.find(m => m.userId === this.user.id) || {};
  }
  @computed get loginState() {
    return !isEmpty(this.OAuthData) && !isEmpty(this.user);
  }
  @computed get userSignedIn() {
    return this.webAppInitialized && this.loginState && !this.isVisitor;
  }


  @computed get provinceField() {
    return Array.isArray(this.profile) &&
      this.profile.find(field => field.name === "provinces");
  }
  @computed get criteriaProvinces() {
    const hasTerritory = o =>
      this.territoryField &&
      this.territoryField.options.some(o1 => o1.dependOnValue === o.name);

    return (
      this.provinceField &&
      this.provinceField.options.filter(hasTerritory)
    ) || this.loadingPickerOptions;
  }
  @computed get territoryField() {
    return Array.isArray(this.profile) &&
      this.profile.find(field => field.name === "territories");
  }
  @computed get criteriaTerritories() {
    const hasCity = o =>
      this.municipalityField &&
      this.municipalityField.options.some(o1 => o1.dependOnValue === o.name);

    const belongsCurrentProvince = o =>
      o.dependOnValue === this.selectedProvince;

    return (
      this.territoryField &&
      this.territoryField.options.filter(
        o => hasCity(o) && belongsCurrentProvince(o)
      )
    ) || this.loadingPickerOptions;
  }
  @computed get municipalityField() {
    return Array.isArray(this.profile) &&
      this.profile.find(field => field.name === "municipalities");
  }
  @computed get criteriaCities() {
    return (this.municipalityField &&
      this.municipalityField.options.filter(
        o => o.dependOnValue === this.selectedTerritory
      )
    ) || this.loadingPickerOptions;
  }

  @computed get campaignPopupResponded() {
    return this.store.campaignPopupResponded;
  }
  @computed get proactiveChatResponded() {
    const { proactiveChatRespondDate } = this.store;
    return (
      this.chatHide ||
      (proactiveChatRespondDate && new Date().getTime() - proactiveChatRespondDate < daysToMilli(1))
    ); // 1 days ago
  }
  @computed get proactiveChatSessionNotified() {
    return !this.tracker.session.id || this.store.proactiveChatSentSessionId === this.tracker.session.id;
  }
  @computed get sessionRandomItems() {
    return this.store.sessionRandomItems;
  }

  constructor() {
    this.storage = new Store("client");
    this.disposers = [
      autorun(this.updateAuthentication),
      autorun(this.storeVisitorOAuthData),
      autorun(this.setDefaultSelected),
      autorun(this.postProvinceChange),
      autorun(this.postTerritoryChange),
      autorun(this.postCityChange)
    ];
    reaction(() => this.searchResults, this.updatePageSearchData);
    reaction(() => this.user, this.updateTrackerUserId);

    this.initTracker();
    this.initSessionRandomItems().catch(console.error);
    this.getSearchFields().catch(console.error);

    window.addEventListener("blur", this.onWindowBlur);
    window.addEventListener("focus", this.onWindowFocus);

    this.adjustEnv();
  }

  adjustEnv = () => {
    if (env === "prod" && window.console) {
      for (const fn in window.console) {
        window.console.hasOwnProperty(fn) &&
        typeof window.console[fn] === "function" &&
        (window.console[fn] = () => {});
      }
    }
  };

  updateAuthentication = () => {
    if (!this.webAppInitialized) return;
    if (this.gotOAuthData) return;

    // If no auth data
    if (isEmpty(this.OAuthData)) {
      this.gotOAuthData = true;
      return this.visitorLogin();
    } else {
      this.gotOAuthData = true;
      if (this.isVisitor) {
        return this.storeVisitorOAuthData();
      } else {
        // Go to marketplace page.
        return this.openUserScratchpad();
      }
    }
  };

  storeVisitorOAuthData = () => {
    if (
      !this.webAppInitialized ||
      !this.isVisitor ||
      isEmpty(this.OAuthData)
    ) return;

    this.store.visitorOAuthData = toJS(this.OAuthData);
  };

  visitorLogin = () =>
    isEmpty(this.visitorOAuthData)
      ? this.execVisitorLogin()
      : this.restoreVisitorLogin();

  execVisitorLogin = () => {
    this.setFrameSrc &&
    this.setFrameSrc(webAppSrcString + "visitor");
  };

  restoreVisitorLogin = () => {
    const refreshToken = this.visitorOAuthData["refresh_token"];
    const data = { refreshToken };
    return this.postFrameMessage && this.postFrameMessage(data);
  };

  openUserScratchpad = () => {
    console.log("Should open scratchpad");
    console.log("User signed in", this.userSignedIn);
    // TODO: Make the app switch to existing scratchpad group in a quicker way.
    const data = { openScratchpad: true };
    return this.postFrameMessage && this.postFrameMessage(data);
  };

  updatePageSearchData = () => {
    if (!this.searchResults) return;
    // const searchProfile = toJS(this.profile);
    const searchProfile = toJS(this.profileData);
    const searchResults = toJS(this.searchResults);
    const pageTracker = this.tracker.pageTracker;
    if (isEmpty(pageTracker)) return;
    const { pages } = pageTracker;
    if (!Array.isArray(pages)) return;

    // If pages.search is one, update this page, otherwise push new search page.
    const searchPages = pages.filter(page => page.name === "search");
    const unusedSearchPage = searchPages.find(page => isEmpty(page.data) && isEmpty(page.submitData));

    // Update first search page object if exist, but create new after the first one.
    if (unusedSearchPage) {
      unusedSearchPage.data = JSON.stringify(searchResults);
      unusedSearchPage.dataType = "matched>groups>profiles";
      unusedSearchPage.submitData = JSON.stringify(searchProfile);
      unusedSearchPage.submitDataType = "criteria>group>profile";
      unusedSearchPage.groupId = this.currentViewGroup.id;
      unusedSearchPage.memberId = this.member.id;
      unusedSearchPage.topicId = this.currentShortlistTopic.id;
      return pageTracker.renewPageActivity(unusedSearchPage, "search");
    }
    return pageTracker.createPageActivity("search", {
      data: JSON.stringify(searchResults),
      dataType: "matched>groups>profiles",
      submitData: JSON.stringify(searchProfile),
      submitDataType: "criteria>group>profile",
      groupId: this.currentViewGroup.id,
      memberId: this.member.id,
      topicId: this.currentShortlistTopic.id
    });
  };

  getSearchFields = async () => {
    const url = `${webAppBackendSrcString}/formExt/public/form/wrapperFields`;
    return axios({
      method: "get",
      url,
      auth: webAppBackendConfig.auth
    })
    .then(response => {
      const profile = response.data;
      return this.profile = profile || {};
    });
  };

  setDefaultSelected = () => {
    const selectDefaultProvElm = () => {
      const defaultProvince = document.getElementById(this.defaultProvince);
      if (defaultProvince) defaultProvince.selected = true;
      return null;
    };

    const selectedProvinceOption =
      this.criteriaProvinces.some(o => o.name === this.selectedProvince);
    if (!selectedProvinceOption && !isEmpty(this.provinceField)) {
      this.selectedProvince = this.defaultProvince;
      setTimeout(selectDefaultProvElm);
    }

    const selectedTerritoryOption =
      this.criteriaTerritories.some(o => o.name === this.selectedTerritory);
    if (!selectedTerritoryOption && !isEmpty(this.territoryField)) {
      this.selectedTerritory = this.criteriaTerritories[0] && this.criteriaTerritories[0].name;
    }

    const selectedCityOption =
      this.criteriaCities.some(o => o.name === this.selectedCity);
    if (!selectedCityOption && !isEmpty(this.municipalityField)) {
      this.selectedCity = this.criteriaCities[0] && this.criteriaCities[0].name;
    }
  };

  setSelectedProvince = province => this.selectedProvince = province;

  setSelectedCity = city => this.selectedCity = city;

  notifyFieldChanges = () => {
    this.postProvinceChange();
    this.postTerritoryChange();
    this.postCityChange();
    return asyncPause(20);
  };

  postProvinceChange = () => {
    const data = {
      provinceChange: this.selectedProvince
    };
    this.postFrameMessage && this.postFrameMessage(data);
  };

  postTerritoryChange = () => {
    const data = {
      territoryChange: this.selectedTerritory
    };
    this.postFrameMessage && this.postFrameMessage(data);
  };

  postCityChange = () => {
    const data = {
      cityChange: this.selectedCity
    };
    this.postFrameMessage && this.postFrameMessage(data);
  };

  postSearchResultFocus = () => {
    const data = {
      searchFocus: true
    };
    this.postFrameMessage && setTimeout(() => this.postFrameMessage(data));
  };

  postShowMoreCriteria = () => {
    const data = {
      criteriaFocus: true,
      moreCriteria: true
    };
    this.postFrameMessage && setTimeout(() => this.postFrameMessage(data));
  };

  postGetCurrentShortlistTopic = () => {
    const data = {
      viewShortlistTopic: true
    };
    this.postFrameMessage && this.postFrameMessage(data);
  };

  postGetProfile = () => {
    const data = {
      marketProfile: true
    };
    this.postFrameMessage && this.postFrameMessage(data);
  };

  postInitProactiveChat = isDevUser => {
    const data = {
      createProactiveChatTopic: { isDevUser }
    };
    this.postFrameMessage && this.postFrameMessage(data);
  };

  postGetProactiveChatTopic = () => {
    const data = {
      getProactiveChatTopic: true
    };
    this.postFrameMessage && this.postFrameMessage(data);
  };

  postChatVisitorOnline = name => {
    const data = {
      proactiveChatVisitor: name
    };
    this.postFrameMessage && this.postFrameMessage(data);
  };

  postChatVisitorOffline = name => {
    const data = {
      proactiveChatVisitorOffline: name
    };
    this.postFrameMessage && this.postFrameMessage(data);
  };

  postChatVisitorNoThanks = name => {
    const data = {
      proactiveChatVisitorNoThanks: name || true
    };
    this.postFrameMessage && this.postFrameMessage(data);
  };

  postChatVisitorLater = name => {
    const data = {
      proactiveChatVisitorLater: name || true
    };
    this.postFrameMessage && this.postFrameMessage(data);
  };

  postChatVisitorLaterTimeout = name => {
    const data = {
      proactiveChatVisitorLaterTimeout: name || true
    };
    this.postFrameMessage && this.postFrameMessage(data);
  };

  postChatVisitorWaiting = () => {
    const data = {
      proactiveChatVisitorWaiting: true
    };
    this.postFrameMessage && this.postFrameMessage(data);
  };

  postChatSend = text => {
    const data = {
      proactiveChatSendMessage: text
    };
    this.postFrameMessage && this.postFrameMessage(data);
  };

  postSessionTimeoutValue = () => {
    const data = {
      sessionTimeoutSetTo: this.sessionExpiryTimeout
    };
    this.postFrameMessage && this.postFrameMessage(data);
  };

  regPostFrameMessageMethod = method => this.postFrameMessage = method;

  regGetFrameSrcMethod = method => this.getFrameSrc = method;

  regSetFrameSrcMethod = method => this.setFrameSrc = method;

  switchToLogin = () => {
    if (responsive.deviceDimension.isLMobile) {
      return window.location.href = webAppSrcString + "logout";
    } else {
      this.marketplaceReady = false;
      this.setFrameSrc(webAppSrcString + "logout");
      return location.hash = "search";
    }
  };

  switchToSearch = async toSearchResult => {
    location.hash = "search";
    if (
      this.webAppInitialized &&
      !this.loginState &&
      !this.userSignedIn &&
      (this.getFrameSrc && this.getFrameSrc().match(/logout/g))
    ) this.visitorLogin();
    return this.queueSearch(toSearchResult);
  };

  queueSearch = async toSearchResult => {
    if (!this.marketplaceReady) {
      reaction(() => this.marketplaceReady, (value, action) => {
        if (!this.marketplaceReady) return;
        action.dispose();
        return setTimeout(() => this.queueSearch(toSearchResult));
      });
      if (this.userSignedIn) return setTimeout(this.openUserScratchpad);
    }
    await this.notifyFieldChanges();
    toSearchResult
      ? this.postSearchResultFocus()
      : this.postShowMoreCriteria();
  };

  execLogout = () => {
    this.marketplaceReady = false;
    this.visitorLogin();
  };

  initTracker = () => {
    if (!this.webAppInitialized) {
      return reaction(() => this.webAppInitialized, (value, action) => {
        if (!this.webAppInitialized) return;
        action.dispose();
        return this.initTracker();
      });
    }
    if (this.tracker) return;

    console.log("Tracker init");
    const clientInfo = {
      deviceId: this.deviceId,
      userId: this.user.id,
      trackElement: document.getElementById("root")
    };
    this.tracker = !isCrawler && new Tracker(clientInfo);

    // this.initCampaignPopup();

    this.tracker.hasSession()
    .then(this.postSessionTimeoutValue)
    // .then(this.initProactiveChat)
    .then(this.updateSessionAbTestData)
    .then(this.bindRandomizerSessionExpiry);

    return this.onPageChange(this.currentPage);
    // this.addInitialActivity();
  };

  updateTrackerUserId = () => {
    if (isEmpty(this.user) || !this.tracker || !this.tracker.session.id) return;
    this.tracker.userId = this.user.id;
    return this.tracker.renewSession(this.tracker.session);
  };

  onPageChange = pageName => {
    pageName = pageName || "home";
    this.prevPage = this.currentPage;
    this.currentPage = pageName;
    if (pageName === "home") {
      this.restorePageScrollPos(pageName);
    } else {
      setTimeout(() => this.restorePageScrollPos(pageName), 300);
    }
    if (!this.tracker) return;
    return this.tracker.pageTracker.switchTrackPage(this.currentPage)
  };

  setPageScrollPos = async pageName => {
    if (!this.rootContainerElm) return;
    pageName = pageName || "home";
    const scrollTop = this.rootContainerElm.scrollTop;
    return this.scrollingPos[pageName] = scrollTop;
  };

  restorePageScrollPos = pageName => {
    if (!this.rootContainerElm) return;
    if (!this.scrollingPos) return;
    pageName = pageName || "home";
    const scrollTop = this.scrollingPos[pageName];
    this.rootContainerElm.scrollTop = scrollTop || 0;
  };

  getCurrentViewGroupAndTopic = () => this.postGetCurrentShortlistTopic();


  /** CampaignPopup **/
  initCampaignPopup = () => {
    const decideShowPopup = () => {
      if (this.userSignedIn || this.campaignPopupResponded) return;
      return this.campaignPopupVisible = true;
    };
    return setTimeout(decideShowPopup, this.popupDelayAfterSessionInit);
  };

  onCampaignPopupYes = () => {
    return this.updateCampaignSessionResponse(1)
    .then(() => window.location.href = "/covid19_home_care");
    // this.campaignPopupShowForm = true;
    // return this.updateCampaignSessionResponse(1);
  };

  onCampaignPopupNo = () => {
    this.store.campaignPopupResponded = true;
    this.campaignPopupVisible = false;
    !this.tracker.session.campaignResponse && this.updateCampaignSessionResponse(0);
  };

  onCampaignPopupFormSubmit = async data => {
    if (isEmpty(data)) return;
    const postData = {};
    for (const field of data) {
      if (field.name.match("preferred_days") && field.value === "on") {
        if (!postData["preferred_days"]) postData["preferred_days"] = [];
        postData["preferred_days"].push(capitalize(field.name.split("preferred_days.")[1]));
        continue;
      }
      if (field.name.match("preferred_times") && field.value === "on") {
        if (!postData["preferred_times"]) postData["preferred_times"] = [];
        postData["preferred_times"].push(capitalize(field.name.split("preferred_times.")[1]));
        continue;
      }
      postData[field.name] = field.value;
    }
    if (this.currentCampaignId === "cleaning_2020-03") {
      postData["preferred_days"] = postData["preferred_days"] ? postData["preferred_days"].join(", ") : "";
      postData["preferred_times"] = postData["preferred_times"] ? postData["preferred_times"].join(", ") : "";
    }
    postData["campaign"] = this.currentCampaignId;
    postData["submit_time"] = new Date().getTime();
    this.campaignPopupSubmitting = true;
    // Async call endpoint so the user doesn't wait too long.
    setTimeout(() => this.updateCampaignSessionData(postData).catch(err => {
      console.warn(err);
      this.store.campaignPopupResponded = false;
      alert("We are sorry, there was an error submitting your request, please try again by refreshing the page.");
    }));
    return asyncPause(1000)
    .then(() => {
      this.campaignPopupShowForm = false;
      this.campaignPopupShowFinish = true;
    })
    .finally(() => this.campaignPopupSubmitting = false);
  };

  onCampaignPopupClose = () => {
    this.campaignPopupShowFinish = false;
    this.campaignPopupShowForm = false;
    this.campaignPopupVisible = false;
  };

  updateCampaignSessionResponse = async campaignResponse => {
    const session = {
      ...this.tracker.session,
      campaignResponse
    };
    return this.tracker.renewSession(session);
  };

  updateCampaignSessionData = async data => {
    const session = {
      ...this.tracker.session,
      campaignResponse: 1, // Set response to 1 in case session expires and is new session.
      campaignData: JSON.stringify(data)
    };
    this.store.campaignPopupResponded = true;
    return this.tracker.renewSession(session);
  };

  /** Proactive Chat **/
  initProactiveChat = async () => {
    console.log("ProactiveChat init");

    console.log("isCrawler", isCrawler);
    if (isCrawler) return;
    if (!this.tracker) {
      await asyncPause(500);
      console.log("ProactiveChat waiting for tracker");
      return this.initProactiveChat();
    }
    console.log("ProactiveChat waiting for session");
    await this.tracker.hasSession();
    await when(() => this.loginState);

    if (this.userSignedIn || this.proactiveChatResponded) return;

    if (this.proactiveChatInitialzing) return;
    this.proactiveChatInitialzing = true;

    // console.log(this.tracker.session.id, this.store.proactiveChatSentSessionId);
    if (this.proactiveChatSessionNotified) {
      this.postGetProactiveChatTopic();
    } else {
      this.postInitProactiveChat(this.isDevUserEnv);
    }

    const post = () => {
      clearTimeout(this.visitorWaitingTimer);
      if (!this.isVisitor || this.userSignedIn) return;
      if (this.chatPopupStatus === "chat" || this.chatHide || this.proactiveChatResponded) return;
      this.postChatVisitorWaiting();
      return this.visitorWaitingTimer = setTimeout(post, 5000);
    };

    reaction(() => this.chatTopic, (data, reaction) => {
      if (isEmpty(data)) return reaction.dispose();
      this.store.proactiveChatSentSessionId = this.tracker.session.id;
      post();
      return reaction.dispose();
    });
  };

  onChatPopupYes = () => {
    if (!this.chatAgentOnline) return this.chatPopupStatus = "awayForm";
    const { profile } = this.defaultGroup;
    const displayName = getDisplayNameEng(profile);
    if (!displayName) return this.chatPopupStatus = "form";
    return this.onChatPopupConfirm(displayName);
  };

  onChatPopupNo = () => {
    this.store.proactiveChatRespondDate = new Date().getTime();
    this.chatPopupVisible = false;
    const { profile } = this.defaultGroup;
    const displayName = getDisplayNameEng(profile);
    return this.postChatVisitorNoThanks(displayName);
  };

  onChatPopupLater = () => {
    const { profile } = this.defaultGroup;
    const displayName = getDisplayNameEng(profile);
    this.chatPopupMinimized = true;
    this.chatPopupLaterTimeout = setTimeout(() => {
      this.chatHide = true;
      this.chatPopupVisible = false;
      return this.postChatVisitorLaterTimeout(displayName);
    }, this.sessionExpiryTimeout);
    return this.postChatVisitorLater(displayName);
  };

  onChatPopupRestore = () => {
    clearTimeout(this.chatPopupLaterTimeout);
    return this.chatPopupMinimized = false;
  };

  onChatPopupConfirm = name => {
    if (!this.chatAgentOnline) return this.chatPopupStatus = "awayForm";
    this.chatPopupStatus = "chat";
    this.chatMessageInactiveMonitor();
    return this.postChatVisitorOnline(name);
  };

  onChatPopupReinit = async () => {
    this.chatPopupStatus = "awayConfirm";
    await asyncPause(3000);
    this.chatPopupVisible = false;
    this.chatPopupStatus = "popup";
    return this.postInitProactiveChat(this.isDevUserEnv);
  };

  onChatAgentOnline = agentName => {
    clearTimeout(this.chatAgentOnlineTimeout);
    this.chatAgentOnline = true;
    this.chatAgentName = agentName;
    if (this.proactiveChatResponded) return;
    !this.chatPopupVisible && this.playSound();
    this.chatPopupVisible = true;
    // Switch the dot to blank after 10 seconds of no SSE.
    this.chatAgentOnlineTimeout = setTimeout(() => this.chatAgentOnline = false, 15000);
  };

  onChatMessageSend = async text => this.postChatSend(text);

  onChatEnd = async sendCopyTo => {
    this.chatPopupStatus = "ended";
    this.disposeChatMessageInactiveMonitor();
    const { profile } = this.defaultGroup;
    const name = getDisplayNameEng(profile);
    this.postChatVisitorOffline(name);
    if (sendCopyTo && validateEmail(sendCopyTo)) {
      await this.sendChatTranscript(toJS(this.chatMessages), sendCopyTo);
    }
    await asyncPause(3000);
    this.chatPopupVisible = false;
    return this.chatHide = true;
  };

  sendChatTranscript = async (messages, visitorEmail) => {
    if (isEmpty(messages) || !visitorEmail) return;
    const proactiveChatArr = messages
    .filter(m => !m.loading)
    .map(m => ({
      sender: m.sender,
      text: m.text,
      date: m.date && m.date.getTime()
    }));
    const { profile } = this.defaultGroup;
    const visitorName = getDisplayNameEng(profile);
    const timezone = jstz.determine();
    const timezoneName = timezone.name();
    const data = {
      visitorName,
      advisorName: this.chatAgentName,
      timezoneName,
      visitorEmail,
      proactiveChatArr
    };
    return axios({
      method: "post",
      url: `${webAppBackendSrcString}/messaging/messaging/proactiveChatHistory`,
      data,
      headers: { Authorization: `Bearer ${this.visitorOAuthData["access_token"]}` }
    })
    .catch(err => {
      console.warn(err);
      return Promise.resolve();
    });
  };

  chatMessageInactiveMonitor = () => {
    const disposeTitleFlashMonitor = reaction(
      () => this.chatMessageUnread,
      (value, reaction) => {
        clearInterval(this.faviconFlashTimer);
        clearTimeout(this.faviconFlashTimer1);
        if (this.chatMessageUnread === 0) {
          this.faviconElm.attr("href", "/favicon.png");
          return document.title = this.currentTitle;
        }
        const flashInterval = 500;
        this.faviconFlashTimer = setInterval(() => {
          this.faviconElm.attr("href", require("../assets/favicon-notify.png"));
          this.faviconFlashTimer1 = setTimeout(() => this.faviconElm.attr("href", "/favicon.png"), flashInterval);
        }, flashInterval * 2);
        document.title = `(${this.chatMessageUnread}) ${this.currentTitle}`;
        return this.playSound();
      }
    );
    const disposeChatMessagesMonitor = reaction(
      () => this.chatMessages,
      (value, reaction) => {
        if (!this.windowFocused) {
          this.chatMessageUnread = this.chatMessages.length - this.chatLastMessagesCount;
        } else {
          this.chatLastMessagesCount = this.chatMessages.length;
        }
      }
    );
    return this.disposeChatMessageInactiveMonitor = () => {
      disposeTitleFlashMonitor();
      disposeChatMessagesMonitor();
    }
  };

  onWindowFocus = event => {
    this.windowFocused = true;
    // if (this.chatMessageUnread > 0) alert("You have a new message in the myCareBase chat.");
    this.chatMessageUnread = 0;
    this.chatLastMessagesCount = this.chatMessages.length;
  };

  onWindowBlur = event => this.windowFocused = false;

  playSound = () => {
    const sound = new Audio(require("../assets/light.mp3"));
    return sound.play();
  };

  initSessionRandomItems = async () => {
    await this.storage.isReady();
    const now = new Date().getTime();
    if (!this.sessionRandomItems || this.sessionRandomItems.expiry <= now) {
      this.store.sessionRandomItems = {
        expiry: now + minutesToMilli(SessionExpiryTimeout),
        items: []
      };
    }
    this.sessionRandomItemsReady = true;
  };

  getSessionRandomItem = component => {
    if (isEmpty(this.sessionRandomItems) || isEmpty(this.sessionRandomItems.items)) return;
    return this.sessionRandomItems.items.find(i => i.component === component);
  };

  onRandomizerLoad = async item => {
    if (item === "default") return;
    const existing = this.getSessionRandomItem(item.component);
    if (existing) return existing.id = item.id;
    const randomItem = {
      id: item.id,
      component: item.component
    };
    this.sessionRandomItems.items.push(randomItem);
    return this.updateSessionAbTestData();
  };

  updateSessionAbTestData = () => {
    if (!this.tracker || !this.tracker.session.id) return;
    this.tracker.abTestData = this.abTestData;
    return this.tracker.renewSession(this.tracker.session);
  };

  bindRandomizerSessionExpiry = () =>
    this.disposers.push(autorun(() => {
      if (!this.tracker || !this.tracker.session.id) return;
      const lastActiveTime = this.tracker.session.lastActiveTimeLong;
      if (lastActiveTime) {
        this.sessionRandomItems.sessionId = this.tracker.session.id;
        this.sessionRandomItems.expiry = lastActiveTime + this.tracker.sessionExpiryTimeout;
      }
    }));

}

export const client = new Client();
window["client"] = client;