import React from "react";
import { observer } from "mobx-react";
import "./styles.css";
import { client } from "../../client";

export const Burger = observer(props => (
  <div className="burger" onClick={props.onClick}>
    <span />
    <span />
    <span />
  </div>
));

export const MenuLinks = observer(props => (
  props.links.map(link => {
    const isCurrent = props.current ? props.current === link.name : link.default;

    const linkStyle = (link.detectUser && client.webAppInitialized) ? (() => (
      (client.userSignedIn ? link.loggedInStyle : link.loggedOutStyle) || link.style
    ))() : link.style;
    const linkDisplay = (link.detectUser && client.webAppInitialized) ? (() => (
      (client.userSignedIn ? link.loggedInDisplay : link.loggedOutDisplay) || link.display
    ))() : link.display;
    const linkPath = (link.detectUser && client.webAppInitialized) ? (() => (
      (client.userSignedIn ? link.loggedInPath : link.loggedOutPath) || link.path
    ))() : link.path;
    const linkClick = (link.detectUser && client.webAppInitialized) ? (
      (client.userSignedIn ? link.loggedInClick : link.loggedOutClick) || link.onClick
    ) : link.onClick;

    return !link.hidden && (
      <a
        key={link.name}
        className="headerLink"
        style={linkStyle}
        target={link.newTab && "_blank"}
        href={linkPath}
        onClick={linkClick}
      >
        <div className={`headerLinkText ${isCurrent ? "current" : ""}`}>
          {linkDisplay}
        </div>
      </a>
    );
  }).filter(Boolean)
));

export const HeaderLogo = observer(props => (
  <a href="/#" style={{ marginRight: 50 }}>
    <div className="headerLogoContainer">
      <img alt="myCareBase Logo" src={require("../../assets/myCareBase_logo2.png")} />
    </div>
  </a>
));

export const PageHeader = observer(props => (
  props.isMobile ? (
    <div className="headerMenu">
      <HeaderLogo />
      <Burger onClick={props.toggleSidebar} />
    </div>
  ) : (
    <div className="headerMenu container">
      <HeaderLogo />
      <div className="headerLinkContainer">
        <MenuLinks {...props} />
      </div>
    </div>
  )
));