import { observer } from "mobx-react";
import React from "react";
import { Section } from "../Section";
import { SectionTile } from "../SectionTile";
import { SectionContent } from "../SectionContent";
import { SectionFooter } from "../SectionFooter";
import { SectionSubHeading } from "../SectionSubHeading";
import { SectionText } from "../SectionText";
import "./styles.css";
import { IoIosChatbubbles, IoIosHeart, IoIosPeople } from "react-icons/io";
import { Link } from "../Link";
import { Tel } from "../Tel";

export const Easy123Section = observer(props => (
  <Section heading="Quality home care is as easy as 1-2-3">
    <SectionContent className="easy123SectionContent">

      <SectionTile
        noCursor
        className="easy123SectionTile"
        icon={{ icon: IoIosPeople, size: 115 }}
        heading="1. Browse our profiles"
      >
        Find potential caregivers based on
        their experience, expertise, and skills
      </SectionTile>

      <SectionTile
        noCursor
        className="easy123SectionTile"
        icon={{ icon: IoIosChatbubbles }}
        heading="2. Evaluate and hire candidates"
      >
        Chat with potential candidates to
        help identify the best fit for your family
        and hire them to start right away
      </SectionTile>

      <SectionTile
        noCursor
        className="easy123SectionTile"
        icon={{ icon: IoIosHeart }}
        heading="3. Manage and monitor"
      >
        Use our app to manage and
        monitor tasks and observations
      </SectionTile>

    </SectionContent>

    <SectionFooter>
      <SectionSubHeading
        className="easy123FooterHeading"
        content="Want some help?"
      />
      {/* FELIX: Next 1 <br /> OK */}
      <SectionText className="easy123FooterText">
        Call our care advisors at <Tel /> <br />
        to help you find the perfect caregiver for your loved one, or send any questions through our <Link href="#contact-us">contact form</Link>
      </SectionText>
    </SectionFooter>

  </Section>
));