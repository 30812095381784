import React from "react";
import { observer } from "mobx-react";
import { client } from "../../client";
import { CriteriaSection } from "../../components/CriteriaSection";
import { Easy123Section } from "../../components/Easy123Section";
import { AdvantagesSection } from "../../components/AdvantagesSection";
import { CaregiversSection } from "../../components/CaregiversSection";
import { TestimonialsSection } from "../../components/TestimonialsSection";
import { ResourcesSection } from "../../components/ResourcesSection";
import { GetStartedSection } from "../../components/GetStartedSection";
import { PageFooter } from "../../components/PageFooter";
import { preventDefaultStopProp } from "../../utils/helpers";

@observer
class HomePage extends React.Component {
  renderDebug = () =>
    <>
      <p>Device ID: {client.deviceId}</p>
      <p>Client ID: {client.clientId}</p>
      <p>Login state: {client.loginState.toString()}</p>
      <p>Current user: {JSON.stringify(client.user || {}, null, 2).substr(0, 200)}...</p>
      <p>OAuth2 data: {JSON.stringify(client.OAuthData, null, 2)}</p>
    </>;

  onProvinceChange = event => {
    preventDefaultStopProp(event);
    const { target } = event;
    if (!target) return;
    const { value } = target;
    if (value) return client.setSelectedProvince(value);
  };

  onCityChange = event => {
    preventDefaultStopProp(event);
    const { target } = event;
    if (!target) return;
    const { value } = target;
    if (value) return client.setSelectedCity(value);
  };

  onCriteriaSearch = event => {
    preventDefaultStopProp(event);
    return client.switchToSearch(true);
  };

  onShowMoreCriteria = event => {
    preventDefaultStopProp(event);
    return client.switchToSearch();
  };

  render() {
    return <div className="page home">
      <CriteriaSection
        provinces={client.criteriaProvinces || []}
        cities={client.criteriaCities || []}
        onProvinceChange={this.onProvinceChange}
        onCityChange={this.onCityChange}
        onSearch={this.onCriteriaSearch}
      />

      <Easy123Section />
      <AdvantagesSection />
      <CaregiversSection />
      <TestimonialsSection />
      <ResourcesSection />

      <GetStartedSection onSearch={this.onShowMoreCriteria} />

      <PageFooter />
    </div>;
  }
}

export { HomePage };