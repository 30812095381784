import React from "react";
import { observer } from "mobx-react";
import "./styles.css";

export const SectionContent = observer(props => (
  <div className={
    `sectionContent ${props.wrap ? "wrap" : ""} ${props.className || ""}`
  }>
    {props.children}
  </div>
));