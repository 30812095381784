import { observer } from "mobx-react";
import React from "react";
import { Section } from "../Section";
import { SectionTile } from "../SectionTile";
import { SectionContent } from "../SectionContent";
import { SectionText } from "../SectionText";
import "./styles.css";
import { SectionHeading } from "../SectionHeading";
import { Link } from "../Link";
import { SectionSubHeading } from "../SectionSubHeading";

export const CaregiversSection = observer(props => (
  <Section>
    <SectionHeading className="caregiversSectionHeading">
      Our pre-screened, experienced caregivers
    </SectionHeading>
    <SectionText>
      Each of our caregivers are selected based on a rigourous 4-step <br />
      screening process to ensure you’re getting the highest quality of care, <br />
      no matter whom you choose.&nbsp;&nbsp;<br />
      <Link href="#family-caregivers">Learn more ></Link>
    </SectionText>
    
    <SectionSubHeading
      className="caregiversSectionSubHeading"
      content="Here is a sample of a few of our caregivers"
    />
    
    <SectionContent wrap className="caregiversSectionContent">
      {/* https://app.mycarebase.com/AdminCentre?type=3&profile=5005 */}
      <SectionTile
        noCursor
        align="left"
        image={{ alt: "myCareBase Vancouver experienced caregiver", src: require("../../assets/caregiver1.jpg" )}}
        heading="Swanie S."
        content="Vancouver, 11 years"
      />
      {/* https://app.mycarebase.com/AdminCentre?type=3&profile=7689 */}
      <SectionTile
        noCursor
        align="left"
        image={{ alt: "myCareBase Vancouver pre-screened  caregiver", src: require("../../assets/caregiver2.jpg" )}}
        heading="Ana Maria P."
        content="Vancouver, 1 year"
      />
      {/* https://app.mycarebase.com/AdminCentre?type=3&profile=469 */}
      <SectionTile
        noCursor
        align="left"
        image={{ alt: "myCareBase Vancouver experienced caregiver", src: require("../../assets/caregiver3.jpg" )}}
        heading="Sandi L."
        content="Vancouver, 6 years"
      />
      {/* https://app.mycarebase.com/AdminCentre?type=3&profile=139 */}
      <SectionTile
        noCursor
        align="left"
        image={{ alt: "myCareBase Toronto experienced caregiver", src: require("../../assets/caregiver4.jpg" )}}
        heading="Alarice R."
        content="Toronto, 13 years"
      />
    </SectionContent>
  </Section>
));