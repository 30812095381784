import { env } from "./env";

export const webAppConfig = {
  hostname: env === "prod"
    ? "app.mycarebase.com"
    : env === "test"
    ? "qa.mycarebase.com"
    : env === "devtest"
    ? "qa.mycarebase.com"
    : env === "dev"
    ? "qa.mycarebase.com"
    : "localhost",

  https: env !== "dev" && env !== "local",

  port: env === "prod"
    ? 443
    : env === "test"
    ? 443
    : env === "devtest"
    ? 3001
    : env === "dev"
    ? 20001
    : 3000,
  path: "/"
};

export const webAppPortString =
  webAppConfig.port.toString().match(/^80$|^443$/g)
    ? ""
    : `:${webAppConfig.port}`;

export const webAppSrcString = `${
  webAppConfig.https ? "https://" : "http://"
  }${webAppConfig.hostname}${webAppPortString}${webAppConfig.path}`;