import React from "react";
import { observer } from "mobx-react";
import { ExternalPage } from "../ExternalBase";
import $ from "jquery";
import { isEmpty } from "../../utils/helpers";
import { renderToString } from "react-dom/server";
import { FaChevronCircleLeft } from "react-icons/fa";

@observer
class ResourcesPage extends ExternalPage {
  loaded = false;
  hostedPath = "resources";
  isSubPage = false;

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    if (this.props.visible && !this.loaded) this.onLoad();
  }

  onLoad = () => {
    this.loaded = true;
    const resourcePageHostedPath = location.pathname.replace("/", "").split("/")[1];
    if (resourcePageHostedPath && resourcePageHostedPath !== "blog") {
      this.hostedPath = `${this.hostedPath}/${resourcePageHostedPath}`;
      this.isSubPage = true;
    }
    this.setHostedPath(this.hostedPath);
    this.onPageLoad(this.specialTreatments)
  };

  // TODO: Links in the page need some work.
  specialTreatments = () => {
    const $subDoc = $("<div></div>").append(this.hostedHtml);

    if (!this.isSubPage) $subDoc.find(".uk-section").first().remove();

    $subDoc.find(".uk-grid-large").css({
      display: "flex",
      flexWrap: "wrap"
    });
    $subDoc.find(".uk-text-center").css("width", "100%");

    const $links = $subDoc.find("a");
    $links.attr("target", "_blank");

    if (this.isSubPage) {
      const title = $subDoc.find("h1").html().trim();
      const $title = $("title");
      if (title && !isEmpty(Array.from($title))) {
        $title.html(title);
      }
      const backToResDiv = additionalStyles =>
        `<div 
            style="height: 100px; display: flex; justify-content: flex-start; align-items: center; ${additionalStyles}"
            class="uk-container uk-container-small backToResources"
        >
          <a onclick="parent.window.subPageTestParentExists()">
            <strong>${renderToString(<FaChevronCircleLeft/>)}&nbsp;&nbsp;Back to Resources</strong>
          </a>
        </div>`;
      const $main = $subDoc.find("#tm-main");
      $main.css("padding-top", 0);
      $main.css("padding-bottom", 0);
      $main.prepend(backToResDiv(`margin-bottom: 20px`));
      $subDoc.find("h1.uk-margin-large-top").removeClass("uk-margin-large-top");
      $subDoc.find(".uk-margin-medium-top").removeClass("uk-grid");
      $main.append(backToResDiv(`margin-top: 30px; margin-bottom: 30px`));

      window.subPageTestParentExists = () => {
        if (window.opener != null && !window.opener.closed) {
          window.close();
        } else {
          window.location.href = "/#resources";
        }
      }
    }

    return this.hostedHtml = $subDoc.html();
  };
}

export { ResourcesPage };