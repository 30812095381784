import * as publicIp from "public-ip";

export const getIpAddress = async () => {
  const options = {
    fallbackUrls: [
      'https://ifconfig.co/ip'
    ]
  };
  const ipv4 = await publicIp.v4(options).catch(console.warn);
  // const ipv6 = await publicIp.v6(options).catch(console.warn);

  return {
    ipv4,
    // ipv6
  }
};