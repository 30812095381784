import React from "react";
import { observer } from "mobx-react";
import { ExternalPage } from "../ExternalBase";
import $ from "jquery";
import { proxy } from "../../client/proxy";

@observer
class ContactPage extends ExternalPage {
  loaded = false;
  hostedPath = "contact-us/request-form";

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    if (this.props.visible && !this.loaded) this.onLoad();
  }

  onLoad = () => {
    this.loaded = true;
    this.setHostedPath(this.hostedPath);
    this.onPageLoad(this.specialTreatments)
  };

  specialTreatments = () => {
    const $subDoc = $("<div></div>").append(this.hostedHtml) ;

    const appendixFunc = `(() => {
      const successHTML = name => '<div class="rsform">' +
        '<p>Dear ' + name + ',</p>' +
        '<p>Thank you for your submission. One of our staff members will contact you as soon as possible.</p>' +
        '<div class="formResponsive">' +
        '<button type="button" class="rsform-submit-button rsform-thankyou-button" name="continue">Continue</button>' +
        '</div>' +
      '</div>';
      
      const $form = $("#userForm");
      const $formFields = $form.find("input, select, textarea");
      const $submitButton = $("#Submit");
      
      const formValidations = {
        "form[FullName]": "Please type your full name.",
        "form[Email]": "Invalid email address.",
      };
      const formValidFields = {
        "form[Comment]": true
      };
      const formData = {
        "g-recaptcha-response": true,
      };
      
      let hasError;
      const setError = ($field, error) => {
        const $validationContainer = $field.parent().parent().find(".formValidation");
        $validationContainer.html('<span class="formError">' + error + '</span>');
      };
      const resetError = (resetFields) => {
        hasError = false;
        $submitButton.attr("disabled", false);
        $formFields.each((i, field) => {
          const $field = $(field);
          resetFields && $field.prop("type") === "text" && (field.value = "");
          setError($field, "");
        });
        resize();
        return hasError;
      } 
      const hasInvalidFields = () => {
        resetError();
        for (const validate in formValidations) {
          if (!!formValidations[validate] && !formData[validate]) {
            hasError = true;
            setError(
              $formFields.parent().parent().find("*[name='" + validate + "']"),
              formValidations[validate]
            );  
          }
        }
        resize();
        return hasError;
      };
      
      const resize = () => {
        const evt = new CustomEvent("frameResize", { bubbles: true, cancelable: false });
        parent.window.dispatchEvent(evt); 
      };
      
      const execSubmit = e => {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();
        
        if (hasInvalidFields()) return;
        e.target.disabled = true;
        
        // return console.log(formData);
        
        $.ajax({
          url: "${proxy.mailerUrl}/contact-us/request-form",
          type: "post",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(formData),
          complete: res => {
            const { status } = res;
            if (status > 201) {
              console.warn(res.responseText);
              resetError();
              return alert("Sorry. something went wrong, please try again later.");
            }
            $(".rsform").html(
              successHTML( formData["form[FullName]"] )
            );
            resize();
            $(".rsform-thankyou-button").click(e => {
              e.stopImmediatePropagation();
              e.preventDefault();
              e.stopPropagation();
              location.reload();
            });
          }
        });

      };
      
      $(".rsform-block-captcha").remove();
      $(".rsform-calendar-button").remove();
      $form.removeAttr("action");
      
      $formFields.on("change", e => {
        const target = e && e.target;
        if (!target) return;
        const { value, name } = target;
        const hasField = formValidations.hasOwnProperty(name) || !!formValidFields[name];
        if (hasField) formData[name] = value;
      });
      
      $(".rsform-reset-button").click(resetError);
      $submitButton.click(execSubmit);
      
      resetError();
    })()`;

    $subDoc.prepend(`<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js" />`);
    $subDoc.append(`<script>${appendixFunc.toString()}</script>`);

    const email = "stephanie@mycarebase.com";
    const mailToLink = `<a href="mailto:${email}">${email}</a>`;
    $subDoc.find("*[data-id='page#12']").find("span").html(mailToLink);
    // $subDoc.find(".rsform-block-consent > span").html(mailToLink);

    return this.hostedHtml = $subDoc.html();
  };
}

export { ContactPage };