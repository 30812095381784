import React from "react";
import { observer } from "mobx-react";
import { ExternalPage } from "../ExternalBase";
import $ from "jquery";

@observer
class Vancity extends ExternalPage {
  loaded = false;
  hostedPath = "Vancity";

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    if (this.props.visible && !this.loaded) this.onLoad();
  }

  onLoad = () => {
    this.loaded = true;
    this.setHostedPath(this.hostedPath);
    this.onPageLoad(this.specialTreatments);
  };

  specialTreatments = () => {
    const $subDoc = $("<div></div>").append(this.hostedHtml);

    const style = `
      .keyframed {
        opacity: 0;
        position: relative;
        animation: show 1s ease .5s forwards;
      }  
      @keyframes show {
        0% {
          transform: translate3d(0, 50px, 0);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    `;

    $subDoc.append(`<style>${style}</style>`);
    $subDoc.find("." + $.escapeSelector("uk-width-1-2@m")).children().addClass("keyframed");

    const $contact = $subDoc.find("a[href='##contact-us']");
    $contact.removeAttr("href");
    $contact.attr("onclick", "parent.location.href = '/contact-us'");

    const email = "stephanie@mycarebase.com";
    const mailToLink = `<a href="mailto:${email}">${email}</a>`;
    $subDoc.find("#features").find("span").html(mailToLink);

    return this.hostedHtml = $subDoc.html();
  }
}

export { Vancity };