const pageHelmets = [
  {
    name: "home",
    title: "Senior Home Care Assistance - Home Care Services For Seniors",
    keywords: "home care vancouver, senior care vancouver, senior care, senior care, senior home care, elder care",
    description: "We provide the best possible senior home care assistance and home care services for seniors in Vancouver & Toronto. Ask us about our elder care services.",
    hashPath: "#"
  },
  {
    name: "search",
    title: "Senior Home Care Assistance - Home Care Services For Seniors",
    keywords: "home care vancouver, senior care vancouver, senior care, senior care, senior home care, elder care",
    description: "We provide the best possible senior home care assistance and home care services for seniors in Vancouver & Toronto. Ask us about our elder care services.",
    hashPath: "#search"
  },
  {
    name: "family-caregivers",
    title: "Family Caregiver, Home Care Vancouver, Senior Home Care Vancouver",
    keywords: "Family Caregivers, Care Receivers, home care vancouver, housekeeping, caregiver jobs, caregiver jobs, home care vancouver, home health agencies hiring near me, caregiver for hire, caregiver for senior",
    description: "Reduce the stress with easy-to-find affordable senior care services Connects you directly with professional caregivers. Best Home Care Vancouver, Toronto",
    hashPath: "#family-caregivers"
  },
  {
    name: "paid-caregivers",
    title: "Paid Caregivers | Senior Care | Caregivers Jobs",
    keywords: "caregiver marketplace, home care vancouver, home health agencies hiring near me, part time caregiver, caregiver websites, care for hire, Paid Caregivers, looking for caregivers, better quality care",
    description: "Want to earn more money, connect with more clients, and have more control over your time? Connects you to home health agencies hiring caregivers.",
    hashPath: "#paid-caregivers"
  },
  {
    name: "contact-us",
    title: "Contact Us | Affordable Home Care Services",
    keywords: "Family Caregivers, caregiver jobs, caregiver for senior, free caregiver, Senior Personal Care, senior care assessment, senior dementia care, senior nursing care, post discharge care, Medication-reminders, Administer Medication, Help with Senior Mental Health issues, Help with Senior cognitive decline,",
    description: "myCareBase provides affordable home care services for seniors and is designed to help alleviate family caregivers’ stress.",
    hashPath: "#contact-us"
  },
  {
    name: "about-us",
    title: "About Us | myCareBase Innovative Care Management Tools",
    keywords: "Alzheimer’s care at home, Parkinsons care at home, Meal preparation services for seniors, Companionship for seniors, Companionship for Assisted Living residents, Companionship for long term care residents, Senior Personal Care, senior care assessment, senior dementia care, senior nursing care, post discharge care, Medication-reminders, Administer Medication, Help with Senior Mental Health issues, Help with Senior cognitive decline,",
    description: "The mission of myCareBase is to help seniors age in place for as long as possible, by enabling them to access affordable home care services.",
    hashPath: "#about-us",
  },
  {
    name: "resources",
    title: "Resources | Caregiver Marketplace | Online access to affordable PSWs, Health Care Aides and other home service providers ",
    keywords: "Family Caregivers, caregiver jobs, caregiver for senior, free caregiver, Senior Personal Care, senior care assessment, senior dementia care, senior nursing care, post discharge care, Medication-reminders, Administer Medication, Help with Senior Mental Health issues, Help with Senior cognitive decline,",
    description: "Caregiver Marketplace connects you directly with professional caregivers online and with other home care service providers in Toronto and Vancouver.",
    hashPath: "#resources"
  },
  {
    name: "mycarebase-concierge",
    title: "Senior care services | Senior Care Vancouver & Toronto",
    keywords: "",
    description: "Providing the necessary senior care services for the loved one is the first priority, there are other important tasks which cannot be left unaddressed. Senior Vancouver, Toronto",
    hashPath: "#mycarebase-concierge"
  },
  {
    name: "terms-of-use",
    title: "Terms of Use | Home Care Vancouver, Toronto | Senior Care",
    keywords: "private duty jobs on craigslist, jobs hiring in chillicothe ohio, caring is giving llc, jobs in san tan valley az, caregiver marketplace",
    description: "myCareBase is an online care journaling platform provided by web or mobile application and includes all content, updates, and releases, collectively, the Services",
    hashPath: "#terms-of-use"
  },
  {
    name: "privacy-policy",
    title: "Privacy Policy | Senior Care | Elder Care | myCareBase",
    keywords: "female companion, retirement homes winnipeg, night shift jobs vancouver, canadian nanny ca, retirement home jobs ottawa, part time caregiver",
    description: "myCareBase provides family members  with online care journals that keep everyone in the Care Circle connected. We recognize the importance of personal health information.",
    hashPath: "#privacy-policy"
  },
  {
    name: "covid19_home_care",
    title: "Senior Home Care During the Coronavirus (COVID-19) Pandemic",
    keywords: "home care, senior care, senior home care, agency, vancouver, bc, coronvavirus, covid-19",
    description: "We are waiving all requirements for minimum visit duration and frequency of service to accommodate your home care needs during the COVID-19 (Coronavirus) pandemic.",
    hashPath: "covid19_home_care"
  },
  // {
  //   name: "venngo2020",
  //   title: "venngo2020",
  //   keywords: "",
  //   description: "",
  //   hashPath: "#venngo2020"
  // }
  {
    name: "senior-tech-education",
    title: "Senior Technology Lessons",
    keywords: "senior, tech, technology, lessons, classes, training, education, support, computer, PC, Mac, smartphone, phone, mobile, Android, iPhone, iPad, tablet",
    description: "Virtual, one-on-one and customized technology lessons to help seniors improve their tech skills.",
    hashPath: "#senior-tech-education"
  },
  {
    name: "vancity",
    title: "Vancity",
    keywords: "",
    description: "",
    hashPath: "#Vancity"
  }
];

module.exports = pageHelmets;