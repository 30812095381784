import { env } from "./env";

export const webAppBackendConfig = {
  hostname: env === "prod"
    ? "prod.mycarebase.com"
    : env === "test"
    ? "test.mycarebase.com"
    : env === "devtest"
    ? "devtest.mycarebase.com"
    : env === "dev"
    ? "leonli.jateruy.com"
    // Default to devtest for local and anything else.
    : "devtest.mycarebase.com",

  https: env !== "dev",
  port: env === "dev" ? 8080 : 443,
  path: "",
  auth: {
    username: "webapp",
    password: "webapp"
  }
};

export const webAppBackendPortString =
  webAppBackendConfig.port.toString().match(/^80$|^443$/g)
    ? ""
    : `:${webAppBackendConfig.port}`;

export const webAppBackendSrcString = `${
  webAppBackendConfig.https ? "https://" : "http://"
  }${webAppBackendConfig.hostname}${webAppBackendPortString}${webAppBackendConfig.path}`;