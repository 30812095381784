import React from "react";
import { Observer, observer } from "mobx-react";
import { computed, observable } from "mobx";
import { client } from "./client";
import { extraMenuLinks, pages } from "./config/pages";
import { isEmpty, preventDefaultStopProp } from "./utils/helpers";
import { responsive } from "./config/styles/responsive";
import { PageHeader } from "./components/PageHeader";
import { SidebarMenu } from "./components/Sidebar";
import { Helmet } from "react-helmet";
import * as pageHelmets from "./config/pageHelmets";
import "./config/styles/global.css"
import { CampaignPopup } from "./components/CampaignPopup";
import ChatWidget from "./components/ChatWidget";

@observer
export default class App extends React.Component {
  @observable currentPage;
  @observable sidebarOpen = false;

  // TODO: Eventually should bring support to pathname standalone pages globally.
  @observable standalone = false;

  @computed get _currentPage() {
    return pages.find(p => p.name.match(this.currentPage)) || {};
  };

  @computed get currentPageHasNoMenu() {
    return this._currentPage.hideMenu;
  };
  @computed get currentPageHasNoCampaign() {
    return this._currentPage.hideCampaign;
  };
  @computed get currentPageHasNoChat() {
    return this._currentPage.hideChat;
  };

  constructor (props) {
    super(props);
    this.onLocationHashChange();
    window.addEventListener("hashchange", this.onLocationHashChange);
  }

  onLocationHashChange = event => {
    preventDefaultStopProp(event);
    let pageName = window.location.hash.replace("#", "");
    if (!pageName) {
      const potentialPathname = window.location.pathname.replace("/", "").split("/")[0] || "";
      const potentialPage = pages.find(p => potentialPathname.toLowerCase().match(p.name.toLowerCase()));
      if (potentialPage) {
        pageName = potentialPathname;
        this.standalone = potentialPage.standalone;
      }
    }
    this.setSidebarOpen(false);
    return client.setPageScrollPos(this.currentPage)
    .then(() => {
      this.currentPage = pageName.toLowerCase() || "home";
      setTimeout(this.dispatchFrameResize, 200);
      return client.onPageChange(this.currentPage);
    });
  };

  dispatchFrameResize = () => {
    const evt = new CustomEvent("frameResize");
    window.dispatchEvent(evt);
    window.dispatchEvent(evt);
    window.dispatchEvent(evt);
  };

  toggleSidebar = event => {
    preventDefaultStopProp(event);
    this.sidebarOpen = !this.sidebarOpen;
  };

  setSidebarOpen = isOpen => this.sidebarOpen = isOpen;

  renderHelmet = () => {
    // const existingTitle = Array.from(document.getElementsByTagName("title"));
    // if (existingTitle.length > 0) {
    //   existingTitle.forEach(node => node.remove());
    // }
    // const existingMeta = Array.from(document.getElementsByTagName("meta"));
    // if (existingMeta.length > 0) {
    //   existingMeta.forEach(node =>
    //     (node.name === "description" || node.name === "keywords") && node.remove()
    //   );
    // }
    const helmet = pageHelmets.find(
      hm => hm.name === this.currentPage
    ) || pageHelmets[0]; // Defaults to home helmet if not specified.
    return helmet && (
      <Observer>{() => (
        <Helmet>
          <title data-react-helmet="true">{helmet.title}</title>
          <meta name="description" content={helmet.description} data-react-helmet="true" />
          <meta name="keywords" content={helmet.keywords} data-react-helmet="true" />
          {/*<link rel="canonical" href={`https://${location.hostname}:${location.port}/${helmet.hashPath}`} data-react-helmet="true" />*/}
        </Helmet>
      )}</Observer>
    )
  };

  renderHeader = isMobile =>
    !this.currentPageHasNoMenu && <PageHeader
      isMobile={isMobile}
      toggleSidebar={this.toggleSidebar}
      current={this.currentPage}
      links={[...pages, ...extraMenuLinks]}
    />;

  renderPages = () => {
    const hasCurrentPage = pages.some(p => p.name === this.currentPage);
    return pages.map(page => {
      const Page = page.component;
      const isCurrentPage = hasCurrentPage && page.name === this.currentPage;
      const pageStyle = {
        display:
          // TODO: Eventually should bring support to pathname standalone pages globally.
          this.standalone
            ? page.standalone
            ? "flex"
            : "none"
            : hasCurrentPage
            ? isCurrentPage
              ? "flex"
              : "none"
            : page.default
              ? "flex"
              : "none"
      };
      return <Observer key={page.name}>{() => (
        <div
          id={page.name}
          className="page"
          style={pageStyle}
        >
          <Page visible={pageStyle.display === "flex"}/>
        </div>
      )}</Observer>
    });
  };

  renderCampaign = () =>
    !isEmpty(this._currentPage) && !this.currentPageHasNoCampaign && <CampaignPopup
      showPopup={
        client.campaignPopupVisible &&
        (!client.chatPopupVisible ||
          (client.chatPopupStatus !== "chat" &&
            client.chatPopupStatus !== "ended"))
      }
      showForm={client.campaignPopupShowForm}
      showFinish={client.campaignPopupShowFinish}
      buttonsDisabled={client.campaignPopupSubmitting}
      withChat={client.chatPopupVisible && !client.chatPopupMinimized}
      onCampaignPopupYes={client.onCampaignPopupYes}
      onCampaignPopupNo={client.onCampaignPopupNo}
      onCampaignPopupClose={client.onCampaignPopupClose}
      onFormSubmit={client.onCampaignPopupFormSubmit}
    />;

  renderChat = () =>
    this.currentPage && !isEmpty(this._currentPage) && !this.currentPageHasNoChat && <ChatWidget
      initChat={client.initProactiveChat}
      visible={client.chatPopupVisible}
      status={client.chatPopupStatus}
      minimized={client.chatPopupMinimized}
      agentOnline={client.chatAgentOnline}
      agentName={client.chatAgentName}
      messages={client.chatMessages}
      headingOverride={this._currentPage.chatHeadingOverride}
      onPopupYes={client.onChatPopupYes}
      onPopupNo={client.onChatPopupNo}
      onPopupLater={client.onChatPopupLater}
      onPopupRestore={client.onChatPopupRestore}
      onPopupConfirm={client.onChatPopupConfirm}
      onPopupReinit={client.onChatPopupReinit}
      onMessageSend={client.onChatMessageSend}
      onChatEnd={client.onChatEnd}
    />;

  renderMainView = isMobile => <>
    {this.renderHelmet()}
    {this.renderHeader(isMobile)}
    {this.renderPages()}
    {this.renderCampaign()}
    {this.renderChat()}
  </>;

  render() {
    // const isMobile = responsive.deviceDimension.isMobile;
    const isMobile = responsive.deviceDimension.isLMobile;
    return <SidebarMenu
      sidebarOpen={this.sidebarOpen}
      onSetOpen={this.setSidebarOpen}
      current={this.currentPage}
      children={this.renderMainView(isMobile)}
      links={[...pages, ...extraMenuLinks]}
    />;
  }
};