import React from "react";
import { observer } from "mobx-react";

export const SectionTileIcon = observer(props => {
  const Icon = props.icon;
  if (!Icon) return null;
  return (
    <a href={props.href}>
      <Icon color={props.color || "#000"} size={props.size || 100} />
    </a>
  );
});