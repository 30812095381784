import { observer } from "mobx-react";
import React from "react";
import { Section } from "../Section";
import { SectionContent } from "../SectionContent";
import { SectionText } from "../SectionText";
import "./styles.css";
import { SectionHeading } from "../SectionHeading";

export const TestimonialsSection = observer(props => (
  <Section dark>
    <SectionHeading className="testimonialsSectionHeading">
      Testimonials
    </SectionHeading>
    <SectionContent className="testimonialsSectionContent">

      <div className="sectionHalf left">
        <img alt="myCareBase helps senior stay happy at home and gives family peace of mind" src={require("../../assets/testimonial.jpg")} />
      </div>

      <div className="sectionHalf right">
        <SectionText align="left" className="testimonialsSectionText">
          “The process to of finding a care aide for my mom
          was simplified and streamlined by the support of myCareBase.
          I felt confident they understood our family's needs,
          as they found the perfect candidate by screening for what we were looking for.
          It is difficult to describe the level of relief I feel knowing that my mother is secure and
          in caring hands when she is with her care aide.
          I would highly recommend using myCareBase
          to find the support you are looking for to provide care to your loved one.”
          <span className="testimonialsNote">—Heather, Vancouver</span>
        </SectionText>

        <SectionText align="left" className="testimonialsSectionText">
          “Stephanie [my care advisor] removes the angst and uncertainty
          from making choices about when and how to access both our private
          and public (services). Her manner puts one at ease very quickly
          and allows for questioning throughout her presentation. I came
          away with a clearer picture of how to plan for changes and an
          appreciation of the caregivers role. ”
          <span className="testimonialsNote">—William, Vancouver</span>
        </SectionText>
      </div>

    </SectionContent>
  </Section>
));