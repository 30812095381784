import { HomePage } from "../pages/Home";
import { SearchPage } from "../pages/Search";
import { ContactPage } from "../pages/Contact";
import { JobPage } from "../pages/Job";
import { FindCaregiverPage } from "../pages/FindCaregiver";
import { AboutPage } from "../pages/About";
import { ResourcesPage } from "../pages/Resources";
import { PolicyPage } from "../pages/Policy";
import { TermsPage } from "../pages/Terms";
import { ConciergePage } from "../pages/Concierge";
import { webAppSrcString } from "./webApp";
import { client } from "../client";
import { Covid19Page } from "../pages/Covid19";
import { Venngo2020 } from "../pages/Venngo2020";
import { SeniorTechEducation } from "../pages/SeniorTechEducation";
import { Vancity } from "../pages/Vancity";

export const pages = [
  {
    name: "home",
    display: "Home",
    path: "/#",
    component: HomePage,
    default: true
  },
  {
    name: "search",
    display: "Search",
    path: "/#search",
    hidden: true,
    component: SearchPage
  },
  {
    name: "family-caregivers",
    display: "Find a caregiver",
    path: "/#family-caregivers",
    component: FindCaregiverPage
  },
  {
    name: "paid-caregivers",
    display: "Get a caregiving job",
    path: "/#paid-caregivers",
    component: JobPage
  },
  {
    name: "contact-us",
    display: "Contact us",
    path: "/#contact-us",
    component: ContactPage
  },
  {
    name: "about-us",
    display: "About us",
    path: "#about-us",
    hidden: true,
    component: AboutPage
  },
  {
    name: "resources",
    display: "Resources",
    path: "#resources",
    hidden: true,
    component: ResourcesPage
  },
  // {
  //   name: "resources",
  //   display: "Resources",
  //   path: "#resources",
  //   hidden: true,
  //   component: ResourcesPage
  // },
  {
    name: "mycarebase-concierge",
    display: "Care Concierge",
    path: "#mycarebase-concierge",
    hidden: true,
    component: ConciergePage
  },
  {
    name: "terms-of-use",
    display: "Terms of use",
    path: "#terms-of-use",
    hidden: true,
    component: TermsPage
  },
  {
    name: "privacy-policy",
    display: "Privacy policy",
    path: "#privacy-policy",
    hidden: true,
    component: PolicyPage
  },
  {
    name: "covid19_home_care",
    display: "COVID Info",
    path: "/covid19_home_care",
    standalone: true,
    hideCampaign: true,
    component: Covid19Page
  },
  // {
  //   name: "venngo2020",
  //   display: "venngo2020",
  //   path: "#venngo2020",
  //   hidden: true,
  //   component: Venngo2020
  // },
  {
    name: "senior-tech-education",
    display: "Senior Tech Education",
    path: "#senior-tech-education",
    hidden: true,
    hideMenu: true,
    hideCampaign: true,
    hideChat: true,
    component: SeniorTechEducation
  },
  {
    name: "vancity",
    display: "Van City",
    path: "#vancity",
    hidden: true,
    hideMenu: true,
    hideCampaign: true,
    hideChat: true,
    component: Vancity
  },
];

export const extraMenuLinks = [
  {
    name: "signUp",
    detectUser: true,
    loggedInClick: () => location.hash = "search",
    loggedOutClick: () => {
      // client.execLogout();
      const url = `${webAppSrcString}Registration?type=${
        client.currentPage === "paid-caregivers"
          ? "paid"
          : "family"
        }`;
      return window.open(url);
    },
    loggedInDisplay: "App",
    loggedOutDisplay: "Sign up",
    // loggedInStyle: { display: "none" },
    newTab: true
  },
  {
    name: "signIn",
    display: "...",
    // path: `${webAppSrcString}logout`,
    detectUser: true,
    loggedInDisplay: "Sign out",
    loggedOutDisplay: "Sign in",
    loggedInClick: client.execLogout,
    loggedOutClick: client.switchToLogin,
    newTab: true
  }
];